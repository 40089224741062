import { Button, Modal as ModalComponent, ModalBody, ModalFooter, ModalHeader } from '@windmill/react-ui';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ModalProps } from './modal.props';

export const Modal: React.FC<ModalProps> = (props) => {
    const {
        isOpen,
        acceptLabelTx = 'common.accept',
        acceptLabelTxOptions = {},
        cancelLabelTx = 'common.cancel',
        cancelLabelTxOptions = {},
        onAccept = () => { },
        onClose = () => { },
        headerTx = 'common.areYouSure',
        headerTxOptions = {},
        hasFooter = true,
        children,
    } = props;

    const {t} = useTranslation();

    return (
        <>
            <ModalComponent isOpen={isOpen} onClose={onClose}>
                <ModalHeader className="-mt-6">{t(headerTx, headerTxOptions)}</ModalHeader>
                <ModalBody className="mt-4">{children}</ModalBody>
                {hasFooter && <ModalFooter>
                    <Button className="w-full sm:w-auto" layout="outline" onClick={onClose}>
                        {t(cancelLabelTx, cancelLabelTxOptions)}
                    </Button>
                    <Button className="w-full sm:w-auto" onClick={onAccept}>
                        {t(acceptLabelTx, acceptLabelTxOptions)}
                    </Button>
                </ModalFooter>}
            </ModalComponent>
        </>
    );
};
