import React from 'react';
import { TableCell, TableHeader } from '@windmill/react-ui';
import camelCase from 'lodash/camelCase';
import { ChevronDownSmallIcon, ChevronUpSmallIcon } from '../../../../assets/icons';
import { useTranslation } from 'react-i18next';
import { SortableTableSortConfig } from '../../../../hooks';
import { TableHeaderProps } from '../sortable-table.props';

type Props<T> = {
    headerRows: TableHeaderProps<T>[];
    sortConfig: SortableTableSortConfig;
    onRequestSort: (key: string) => void;
    txPrefix: string;
    showOptionsRow: boolean;
}

const SortableTableHeader = <T, >(props: Props<T>) => {
    const {headerRows, sortConfig, onRequestSort, txPrefix, showOptionsRow} = props;
    const {t} = useTranslation();

    const headerClassName = (name: string) => {
        const className = 'cursor-pointer';
        if (!sortConfig) {
            return className;
        }
        return sortConfig.key === name ? `${className} ${sortConfig.direction}` : className;
    };

    return (
        <TableHeader>
            <tr>
                {headerRows.map((headerRow, i) => (
                    <TableCell
                        key={`header-${i}`}
                        className={headerClassName(headerRow.field)}
                        onClick={() => onRequestSort(headerRow.field)}
                    >
                        <div className="flex items-center">
                            {t(`${txPrefix}.${camelCase(headerRow.field)}`)}
                            {!headerRow.notSortable &&
                                <div className="flex ml-auto items-center">
                                    <ChevronDownSmallIcon className="w-5 h-5 descending" aria-hidden="true" />
                                    <ChevronUpSmallIcon className="w-5 h-5 ascending" aria-hidden="true" />
                                </div>
                            }
                        </div>
                    </TableCell>
                ))}
                {showOptionsRow && <TableCell className="text-center">{t('sortableTable.options')}</TableCell>}
            </tr>
        </TableHeader>
    );
};

export default SortableTableHeader;
