import { HelperText, Input, Label } from '@windmill/react-ui';
import { Formik } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';
import Image from '../../assets/img/dpa-vertical.png';
import { Copyright, LoadingButton, PageTitle } from '../index';
import { useResetPasswordMutation, useResetPasswordQuery } from '../../api/users';
import { useToast } from '../../hooks';
import { ApiErrorResponse } from '../../api';

type ResetPasswordForm = {
    newPassword: string;
    confirmPassword: string;
}

const ResetPassword: React.FC = () => {
    const {t} = useTranslation();
    const history = useHistory();
    const params: any = useParams();
    const {successToast} = useToast();
    const onError = (error: ApiErrorResponse) => {
        if (error.kind === 'not-found') {
            history.push('/login');
        }
    };
    useResetPasswordQuery(params.token, {
        onError,
    });
    const {mutate: submitResetPassword} = useResetPasswordMutation({
        onSuccess: () => {
            successToast(t('resetPassword.successMessage'));
            history.push('/admin');
        },
        onError,
    });

    return (
        <div className="max-w-md pt-0 md:pt-5 mx-auto flex flex-col justify-center xs:mt-10 rounded-lg xs:shadow-xl">
            <div className="h-32 w-full mb-5 bg-gray-100 p-5">
                <img
                    aria-hidden="true"
                    className="object-contain object-center w-full h-full"
                    src={Image}
                    alt="logo"
                />
            </div>
            <main className="flex items-center justify-center w-full px-5">
                <div className="w-full">
                    <PageTitle marginClass="mb-4">
                        {t('resetPassword.title', {app: process.env.REACT_APP_NAME})}
                    </PageTitle>
                    <Formik
                        initialValues={{
                            newPassword: '',
                            confirmPassword: '',
                        } as ResetPasswordForm}
                        validate={values => {
                            const errors: Partial<ResetPasswordForm> = {};

                            if (!values.newPassword || values.newPassword.length < 1) {
                                errors.newPassword = t('common.formErrors.required');
                            }

                            if (!values.confirmPassword || values.newPassword.length < 1) {
                                errors.confirmPassword = t('common.formErrors.required');
                            } else if (values.confirmPassword && (values.confirmPassword !== values.newPassword)) {
                                errors.confirmPassword = t('resetPassword.passwordsDontMatchError');
                            }

                            return errors;
                        }}
                        onSubmit={({newPassword, confirmPassword}) => {
                            submitResetPassword({
                                new_password: newPassword,
                                confirm_password: confirmPassword,
                                token: params.token,
                            });
                        }}
                    >
                        {({
                              values,
                              errors,
                              touched,
                              handleChange,
                              handleBlur,
                              handleSubmit,
                          }) => (
                            <form onSubmit={handleSubmit}>
                                <Label className="mt-4">
                                    <span>{t('common.fields.newPassword')}</span>
                                    <Input
                                        css=""
                                        className="mt-1"
                                        type="password"
                                        name="newPassword"
                                        placeholder="********"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.newPassword}
                                        valid={!errors.newPassword && touched.newPassword}
                                    />
                                    {(errors.newPassword && touched.newPassword) &&
                                        <HelperText valid={false}>{errors.newPassword}</HelperText>}
                                </Label>

                                <Label className="mt-4">
                                    <span>{t('common.fields.confirmPassword')}</span>
                                    <Input
                                        css=""
                                        className="mt-1"
                                        type="password"
                                        name="confirmPassword"
                                        placeholder="********"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.confirmPassword}
                                        valid={!errors.confirmPassword && touched.confirmPassword}
                                    />
                                    {(errors.confirmPassword && touched.confirmPassword) &&
                                        <HelperText valid={false}>{errors.confirmPassword}</HelperText>}
                                </Label>

                                <LoadingButton
                                    isLoading={false}
                                    disabled={!!errors.newPassword || !!errors.confirmPassword}
                                    buttonClassName={'mt-4'}
                                >
                                    {t('resetPassword.resetButton')}
                                </LoadingButton>

                                <Copyright className={'block mb-4 mt-6'} />
                            </form>
                        )}
                    </Formik>
                </div>
            </main>
        </div>
    );
};

export default ResetPassword;
