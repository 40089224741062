import { useFeatureFlagsQuery } from '../../api/feature-flags/feature-flags.queries';
import { useFeatureFlagsStore } from '../../stores';
import { useEffect } from 'react';

/**
 * Initializes feature flags
 */
export const useFeatureFlags = () => {
    const {data: featureFlags} = useFeatureFlagsQuery();
    const setFeatureFlags = useFeatureFlagsStore(state => state.setFeatureFlags);

    useEffect(() => {
        setFeatureFlags(featureFlags);
    }, [featureFlags, setFeatureFlags]);
};
