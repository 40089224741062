import React from 'react';
import { Editor, Element as SlateElement, Transforms } from 'slate';
import { useSlate } from 'slate-react';
import { Span } from '../span/span';
import { BlockButtonProps } from './block-button.props';

const LIST_TYPES = ['numbered-list', 'bulleted-list'];

export const BlockButton: React.FC<BlockButtonProps> = ({format, icon}) => {
    const editor = useSlate();

    const isBlockActive = (editor, format) => {
        const [match] = Editor.nodes(editor, {
            match: n =>
                !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === format,
        });

        return !!match;
    };

    const toggleBlock = (editor, format) => {
        const isActive = isBlockActive(editor, format);
        const isList = LIST_TYPES.includes(format);

        Transforms.unwrapNodes(editor, {
            match: n =>
                LIST_TYPES.includes(
                    !Editor.isEditor(n) && SlateElement.isElement(n) && n.type,
                ),
            split: true,
        });
        const newProperties: Partial<SlateElement> = {
            type: isActive ? 'paragraph' : isList ? 'list-item' : format,
        };
        Transforms.setNodes(editor, newProperties);

        if (!isActive && isList) {
            const block = {type: format, children: []};
            Transforms.wrapNodes(editor, block);
        }
    };

    return (
        <Span
            active={isBlockActive(editor, format)}
            icon={icon}
            onMouseDown={event => {
                event.preventDefault();
                toggleBlock(editor, format);
            }}
        />
    );
};
