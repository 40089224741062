import React from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { AccessibleNavigationAnnouncer, Page404, ThemedToast } from './components';
import { ROUTES, TopLevelPageRoute } from './routes';
import { useFeatureFlags, useLanguageControl } from './hooks';
import { useApiDefaultErrorToast } from './hooks/use-api-default-error-toast/use-api-default-error-toast';

const App: React.FC = () => {
    useLanguageControl();
    useFeatureFlags();
    useApiDefaultErrorToast();

    return (
        <>
            <Router>
                <AccessibleNavigationAnnouncer />
                <Switch>
                    {ROUTES.map((route: TopLevelPageRoute, i: number) => (
                        route.component ? (
                            <Route
                                key={i}
                                exact={!route.hasSubRoutes}
                                path={route.path}
                                render={(props) => <route.component {...props} />}
                            />
                        ) : null
                    ))}
                    <Redirect exact from="/" to="/admin" />
                    <Route component={Page404} />
                </Switch>
            </Router>
            <ThemedToast />
        </>
    );
};

export default App;
