import { Badge, DropdownItem } from '@windmill/react-ui';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useGetQuestionsQuery } from '../../../../../api/dma';
import { DropDown, PageTitle, SearchInput } from '../../../../../components';
import QuestionsSectionList from './questions-section-list';
import { useDmaQuestionsTableSearchStore } from '../../../../../stores';
import { useHistory } from 'react-router-dom';
import { PlusIcon } from '../../../../../assets/icons';
import { PermissionMatcher, useAuth } from '../../../../../hooks/use-auth/use-auth';
import { PermissionAction } from '../../../../../api/users';

const permissionToWriteQuestions: PermissionMatcher = {app: 'DMA', resource: 'QUESTIONS', action: PermissionAction.WRITE};

export const DEFAULT_SECTIONS_COUNT = 7;

const QuestionsList: React.FC = () => {
    const {t} = useTranslation();
    const history = useHistory();
    const {data: questionnaireSections = [], isSuccess} = useGetQuestionsQuery();
    const hasSections = questionnaireSections?.length > 0;
    const {
        setSectionIndex: setSelectedSection,
        term: searchTerm,
        setTerm: setSearchTerm,
    } = useDmaQuestionsTableSearchStore();
    const {hasPermission} = useAuth();

    return (
        <>
            <div className="md:flex md:flex-wrap sm:grid sm:grid-cols-3">
                <div className="flex">
                    <PageTitle>{t('questions.title')}</PageTitle>
                </div>
                <div className="flex ml-auto items-center space-x-3 mb-4 sm:pl-4 sm:my-4">
                    <DropDown buttonText={t('questions.sectionDropdown')} align="left" closeOnClick>
                        {hasSections && questionnaireSections.map((section, i) => (
                            <DropdownItem
                                key={i}
                                className="justify-between focus:outline-none"
                                onClick={() => setSelectedSection(i)}
                            >
                                <span className="mr-2">{section.title}</span>
                                <Badge className="px-2">{section.questions.length}</Badge>
                            </DropdownItem>
                        ))}
                        {questionnaireSections?.length === DEFAULT_SECTIONS_COUNT && hasPermission(permissionToWriteQuestions) && (
                            <DropdownItem
                                className="text-secondary focus:outline-none"
                                onClick={() => history.push('/admin/dma/questions/add')}
                            >
                                <PlusIcon />
                                <span className="ml-2">{t('questions.addCustomSection')}</span>
                            </DropdownItem>
                        )}
                    </DropDown>
                </div>
                <div className="flex items-center space-x-3 mb-4 sm:pl-4 sm:my-4">
                    <SearchInput
                        value={searchTerm} onChange={setSearchTerm}
                        placeholderTx="questions.searchPlaceholder"
                    />
                </div>
            </div>

            {hasSections && <QuestionsSectionList />}
            {isSuccess && !hasSections && <h2 className="text-lg">{t('questions.noSections')}</h2>}
        </>
    );
};

export default QuestionsList;
