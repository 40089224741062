import { Alert } from '@windmill/react-ui';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

/**
 * Returns callbacks for success-, info- and error-toasts.
 */
export const useToast = (): { successToast: ToastCallback, infoToast: ToastCallback, errorToast: ToastCallback } => {
    const {t} = useTranslation();
    return {
        successToast: (content: string) => {
            toast.success(() => <Alert type="success">{content}</Alert>);
        },
        infoToast: (content: string) => {
            toast.info(() => <Alert type="info">{content}</Alert>);
        },
        errorToast: (content: string) => {
            toast.error(() => <Alert type="danger">{content?.trim?.() || t('common.errors.unknown')}</Alert>);
        },
    };
};

type ToastCallback = (content: string) => void;
