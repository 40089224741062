import { SortableTableSearch } from '../../components/tables/sortable-table/sortable-table-config';
import { StateCreator } from 'zustand/esm/vanilla';
import { persist, PersistOptions } from 'zustand/middleware';
import create from 'zustand';

export type TableSearchState = SortableTableSearch & {
    setTerm: (term: string) => void;
    setFiltered: (filtered: any[]) => void;
}

const tableSearchStateInitializer: StateCreator<TableSearchState> = (set) => ({
    term: '',
    filtered: [],
    setTerm: (term: string) => set({term}),
    setFiltered: (filtered: any[]) => set({filtered}),
});

const tableSearchPersistOptions: Omit<PersistOptions<TableSearchState, TableSearchState>, 'name'> = {
    getStorage: () => sessionStorage,
};

export const useUsersTableSearchStore = create(persist(
    tableSearchStateInitializer,
    {
        name: 'users-table-search',
        ...tableSearchPersistOptions,
    },
));

export const useCompaniesTableSearchStore = create(persist(
    tableSearchStateInitializer,
    {
        name: 'companies-table-search',
        ...tableSearchPersistOptions,
    },
));

export const useDmaAssessmentsTableSearchStore = create(persist(
    tableSearchStateInitializer,
    {
        name: 'dma-assessments-table-search',
        ...tableSearchPersistOptions,
    },
));

export const useDpaAssessmentResultsTableSearchStore = create(persist(
    tableSearchStateInitializer,
    {
        name: 'dpa-assessment-results-table-search',
        ...tableSearchPersistOptions,
    },
));

export const useDpaCandidatesTableSearchStore = create(persist(
    tableSearchStateInitializer,
    {
        name: 'dpa-candidates-table-search',
        ...tableSearchPersistOptions,
    },
));

type DmaQuestionsTableSearchState = TableSearchState & {
    sectionIndex: number;
    setSectionIndex: (sectionIndex: number) => void;
};
export const useDmaQuestionsTableSearchStore = create<DmaQuestionsTableSearchState>(
    (set) => ({
        term: '',
        filtered: [],
        sectionIndex: 0,
        setTerm: (term: string) => set({term}),
        setFiltered: (filtered: any[]) => set({filtered}),
        setSectionIndex: (sectionIndex: number) => set({sectionIndex}),
    }),
);
