const Theme = {
    // Alert
    alert: {
        neutral: 'bg-teal-100 dark:bg-primary text-primary dark:text-white',
        warning: 'bg-yellow-100 dark:bg-yellow-500 text-yellow-700 dark:text-white',
        icon: {
            neutral: 'text-primary dark:text-gray-200',
            warning: 'text-yellow-400 dark:text-yellow-100',
        },
    },
    // Badge
    badge: {
        base: 'inline-flex text-xs font-medium rounded-full',
        primary: 'text-primary bg-teal-100 dark:text-white dark:bg-primary',
        warning: 'bg-yellow-100 dark:bg-yellow-500 text-yellow-700 dark:text-white',
    },
    // Textarea
    textarea: {
        active:
            'focus:border-teal-100 dark:border-gray-600 dark:focus:border-gray-600 dark:bg-gray-700 dark:focus:shadow-outline-gray focus:shadow-outline-teal',
    },
    // Select
    select: {
        active:
            'focus:border-teal-100 dark:border-gray-600 dark:bg-gray-700 focus:shadow-outline-teal dark:focus:shadow-outline-gray dark:focus:border-gray-600',
    },
    // Input
    input: {
        base: 'block w-full text-sm focus:outline-none dark:text-gray-300 form-input leading-5 border border-gray-100',
        active:
            'focus:border-teal-100 dark:border-gray-700 focus:shadow-outline-teal dark:focus:border-gray-700 dark:focus:shadow-outline-gray dark:bg-gray-700',
        valid:
            'border-tertiary dark:bg-gray-700 focus:border-green-400 dark:focus:border-green-400 focus:shadow-outline-green dark:focus:shadow-outline-green',
        radio:
            'text-primary form-radio focus:border-teal-100 focus:outline-none focus:shadow-outline-teal dark:focus:shadow-outline-gray',
        checkbox:
            'text-primary form-checkbox focus:border-teal-100 focus:outline-none focus:shadow-outline-teal dark:focus:shadow-outline-gray',
    },
    // Button
    button: {
        primary: {
            base: 'text-white bg-primary border border-transparent',
            active: 'active:bg-primary hover:bg-primary-hover focus:shadow-outline-teal',
        },
    },
};

export default Theme;
