import { Avatar, Dropdown, DropdownItem } from '@windmill/react-ui';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { EditIcon, LogoutIcon } from '../../../assets/icons';
import AvatarImage from '../../../assets/img/avatar.jpeg';
import { Modal } from '../modal/modal';
import { useAuth } from '../../../hooks/use-auth/use-auth';
import { useLogoutMutation } from '../../../api/users';
import { useAdminApp } from '../../../hooks';
import { useDisclosure } from '@mantine/hooks';

export const UserAvatar: React.FC = () => {
    const {t} = useTranslation();
    const history = useHistory();
    const activeApp = useAdminApp();

    const [profileMenuOpened, profileMenu] = useDisclosure(false);
    const [confirmationAlertOpened, confirmationAlert] = useDisclosure(false);

    const {resetUser, user} = useAuth();
    const {mutate: logout} = useLogoutMutation({
        onSuccess: () => {
            resetUser();
            history.push('/login');
        },
    });

    return (<>
        <button
            className="rounded-full focus:shadow-outline-purple focus:outline-none"
            onClick={profileMenu.toggle}
            aria-haspopup="true"
        >
            <Avatar
                className="align-middle"
                src={user?.profile_image ? `${process.env.REACT_APP_FILES_URL}/${user.profile_image}` : AvatarImage}
                alt=""
                aria-hidden="true"
            />
        </button>
        <Dropdown isOpen={profileMenuOpened} onClose={profileMenu.close} align="right">
            <DropdownItem className="focus:outline-none">
                <span className={'font-semibold'}>{user?.name}</span>
            </DropdownItem>
            <DropdownItem className="focus:outline-none" onClick={() => history.push(`/admin/${activeApp}/users/edit/${user.id}`)}>
                <EditIcon className="w-4 h-4 mr-3" aria-hidden="true" />
                <span>{t('users.myProfile')}</span>
            </DropdownItem>
            <DropdownItem className="focus:outline-none" onClick={confirmationAlert.open}>
                <LogoutIcon className="w-4 h-4 mr-3" aria-hidden="true" />
                <span>{t('header.logout')}</span>
            </DropdownItem>
        </Dropdown>
        <Modal
            isOpen={confirmationAlertOpened}
            headerTx="header.logoutConfirmation.header"
            acceptLabelTx="header.logoutConfirmation.acceptButton"
            onAccept={logout} // TODO ms: why logout after changing avatar?
            onClose={confirmationAlert.close}
        >
            {t('header.logoutConfirmation.body')}
        </Modal>
    </>);
};
