import React from 'react';
import { Input, TableCell } from '@windmill/react-ui';
import { useForceUpdate } from '@mantine/hooks';

type TableActionCellProps = {
    cellKey: string;
    row: any;
    onChange: (row) => void;
}

export const TableActionCell: React.FC<TableActionCellProps> = ({cellKey, row, onChange}) => {
    const forceUpdate = useForceUpdate();
    return (
        <TableCell
            className="text-center"
        >
            <Input
                css=""
                className="mr-2 border w-5 h-5"
                type="checkbox"
                name={cellKey}
                checked={row.select}
                onChange={() => {
                    onChange(row);
                    forceUpdate(); // rerender cell to update checked state
                }}
            />
        </TableCell>
    );
};
