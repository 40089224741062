import { useAuthStore } from '../../stores';
import { Permission, PermissionAction, PermissionScope } from '../../api/users';
import { useCallback } from 'react';

export type PermissionMatcher = Partial<Permission> | 'AUTHENTICATED';

/**
 * @return methods to access the authenticated user
 */
export const useAuth = () => {
    const {user, setUser} = useAuthStore();

    const resetUser = useCallback(() => setUser(null), [setUser]);

    const isSignedIn = useCallback(() => !!user?.id, [user?.id]);

    const hasPermission = useCallback((permissionMatcher: PermissionMatcher): boolean => {
        if (!permissionMatcher) {
            // Everyone has permission
            return true;
        }

        if (permissionMatcher === 'AUTHENTICATED') {
            // Any authenticated user has permission
            return isSignedIn();
        }

        const {
            app = undefined, // if app is omitted, permission on any app is satisfactory
            resource = undefined, // if resource is omitted, permission on any resource is satisfactory
            scope = PermissionScope.COMPANY, // if scope is omitted, the lowest existing scope is satisfactory
            action = PermissionAction.READ, // if action is omitted, the lowest existing action is satisfactory
        } = permissionMatcher;

        return user?.role?.permissions?.some((permission: Permission) => {
            return (!app || app === permission.app) &&
                (!resource || resource === permission.resource) &&
                (permission.scope >= scope) &&
                (permission.action >= action);
        });
    }, [isSignedIn, user?.role?.permissions]);

    return {
        user,
        setUser,
        resetUser,
        isSignedIn,
        hasPermission,
    };
};
