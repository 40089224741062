import { useEffect } from 'react';
import { useToast } from '../use-toast/use-toast';
import { ApiErrorResponse } from '../../api';
import { useQueryClient } from '@tanstack/react-query';

/**
 * Configures global default error toast on API error.
 */
export const useApiDefaultErrorToast = () => {
    const {errorToast} = useToast();
    const queryClient = useQueryClient();
    useEffect(() => {
        const onError = (error: ApiErrorResponse) => errorToast(error?.message);
        const defaultOptions = queryClient.getDefaultOptions();
        queryClient.setDefaultOptions({mutations: {...defaultOptions.mutations, onError}, queries: {...defaultOptions.queries, onError}});
    }, [queryClient, errorToast]);
};
