import { useTranslation } from 'react-i18next';
import { useSettingsStore } from '../../stores';
import { useEffect } from 'react';

/**
 * Changes i18n translations on language change
 */
export const useLanguageControl = () => {
    const {i18n} = useTranslation();
    const {language} = useSettingsStore();

    useEffect(() => {
        void i18n.changeLanguage(language);
    }, [language, i18n]);
};
