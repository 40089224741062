import React from 'react';
import { AppFallback } from '../app-fallback/app-fallback';

export const Main: React.FC = ({children}) => {
    return (
        <main className="h-full w-full overflow-y-auto bg-gray-50 pb-20">
            <div className="grid px-6 mx-auto">
                <AppFallback>
                    {children}
                </AppFallback>
            </div>
        </main>
    );
};
