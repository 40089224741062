import { Button } from '@windmill/react-ui';
import { ButtonAsButtonProps } from '@windmill/react-ui/dist/Button';
import classNames from 'classnames';
import React from 'react';
import { AnimatedIcon } from '../animated-icon/animated-icon';

type LoadingButtonProps = ButtonAsButtonProps & {
    isLoading: boolean;
    buttonClassName?: string;
}

export const LoadingButton: React.FC<LoadingButtonProps> = (props) => {
    const {layout = 'primary', buttonClassName = '', isLoading = false, children, disabled, type = 'submit'} = props;

    const cls = classNames('relative', buttonClassName);

    return (
        <Button
            layout={layout}
            type={type}
            className={cls}
            block
            disabled={isLoading || disabled}
            icon={() => isLoading && (
                <AnimatedIcon icon="loadingWhite" className="absolute right-0" size={36} />
            )}
        >
            {children}
        </Button>
    );
};
