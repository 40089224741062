import { Button } from '@windmill/react-ui';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Descendant } from 'slate';
import { RichTextInput } from '../../../../../../components';
import { RichElement } from '../../../../../../components/inputs/rich-text-input/rich-text-input.props';
import { useToast } from '../../../../../../hooks';
import { AssessmentResult, useUpdateCommentMutation } from '../../../../../../api/dpa';

const initialValue: RichElement[] = [
    {
        type: 'paragraph',
        children: [{text: ''}],
    },
];

const stringifiedInitialValue = JSON.stringify(initialValue);

type Props = {
    item: AssessmentResult;
    onCommentSave: () => void;
}

export const AssessmentResultsCommentModal: React.FC<Props> = ({item, onCommentSave}) => {
    const {t} = useTranslation();
    const {successToast} = useToast();
    const {mutate: updateComment} = useUpdateCommentMutation(item.id);
    const [comment, setComment] = useState<Descendant[]>(item.comment ? JSON.parse(item.comment) : initialValue);

    const handleSaveComment = () => {
        const stringifiedComment = JSON.stringify(comment);
        updateComment({comment: stringifiedComment === stringifiedInitialValue ? null : stringifiedComment}, {
            onSuccess: () => {
                onCommentSave();
                successToast(t('assessment.result.commentModal.successMessage'));
            },
        });
    };

    return (<>
        <RichTextInput
            value={comment} onChange={setComment}
            placeholderTx={'assessment.result.commentModal.commentPlaceholder'}
        />
        <Button
            className="mt-2"
            onClick={handleSaveComment}
        >{t('assessment.result.commentModal.saveButton')}</Button>
    </>);
};
