import React from 'react';
import { SectionTitleProps } from './section-title.props';

export const SectionTitle: React.FC<SectionTitleProps> = (props) => {
    const {
        className = '',
        marginClass = 'mb-4',
        children,
    } = props;

    return (
        <h2
            className={`${marginClass} ${className} text-lg font-semibold text-gray-600 dark:text-gray-300`}
        >
            {children}
        </h2>
    );
};
