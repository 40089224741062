import { Input } from '@windmill/react-ui';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { CloseIcon, SearchIcon } from '../../../assets/icons';
import { SearchInputProps } from './search-input.props';

export const SearchInput: React.FC<SearchInputProps> = (props) => {
    const {
        value,
        onChange,
        placeholderTx = 'search.placeholder',
        className = 'relative',
    } = props;

    const {t} = useTranslation();

    return (
        <div className={className}>
            <div className="absolute inset-y-0 flex items-center pl-2 text-primary">
                <SearchIcon
                    className="w-4 h-4"
                    aria-hidden="true"
                />
            </div>
            <Input
                css=""
                className="pl-8"
                placeholder={t(placeholderTx)}
                value={value}
                onChange={e => onChange(e.target.value)}
            />
            {value.length > 0 &&
                <div className="text-primary absolute cursor-pointer inset-y-0 mr-1 right-0 pt-2"
                     onClick={() => onChange('')}>
                    <CloseIcon
                        className="w-4 h-5"
                        aria-hidden="true"
                    />
                </div>
            }
        </div>
    );
};
