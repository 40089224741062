import create from 'zustand';
import { persist } from 'zustand/middleware';

export const useSidebarStore = create(persist<Partial<SidebarState>>(
    (set) => ({
        isSidebarOpen: false,
        isSidebarHovered: false,
        isSidebarExpanded: false,
        toggleSidebar: () => set((state) => ({isSidebarOpen: !state.isSidebarOpen})),
        closeSidebar: () => set(() => ({isSidebarOpen: false})),
        toggleSidebarExpansion: () => set((state) => ({
            isSidebarExpanded: !state.isSidebarExpanded,
            isSidebarHovered: false,
        })),
        hoverSidebar: () => set(() => ({isSidebarHovered: true})),
        unhoverSidebar: () => set(() => ({isSidebarHovered: false})),
    } as SidebarState),
    {
        name: 'sidebar',
        getStorage: () => sessionStorage,
        partialize: (state: SidebarState) => ({
            isSidebarOpen: state.isSidebarOpen,
            isSidebarExpanded: state.isSidebarExpanded,
        }),
    },
));

type SidebarState = {
    isSidebarOpen: boolean;
    isSidebarHovered: boolean;
    isSidebarExpanded: boolean;
    toggleSidebar: () => void;
    closeSidebar: () => void;
    toggleSidebarExpansion: () => void;
    hoverSidebar: () => void;
    unhoverSidebar: () => void;
}
