import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { GetQuery } from '../../queries';
import { AssessmentResult, AssessmentResultsByHash } from '../';

export const useGetAllAssessmentResultsQuery = <TData = AssessmentResult[]>(options: UseQueryOptions<AssessmentResult[], unknown, TData> = {}) => {
    return useQuery(
        ['dpa', 'results'],
        GetQuery(`/api/dpa/results`),
        options,
    );
};

export const useGetAssessmentResultsByHashQuery = <TData = AssessmentResultsByHash>(hash: string, options: Omit<UseQueryOptions<AssessmentResultsByHash, unknown, TData>, 'enabled'> = {}) => {
    return useQuery(
        ['dpa', 'results', hash],
        GetQuery(`/api/dpa/results/${hash}`),
        {
            enabled: !!hash,
            ...options,
        },
    );
};
