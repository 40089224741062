import moment, { Moment } from 'moment';
import 'moment/locale/de';

const FIRST_DAY = 1;

export const DATE_FORMAT = 'DD/MM/YY';
export const DATETIME_FORMAT = 'DD/MM/YY HH:mm:ss.SSS';

const PRETTY_FORMAT = `${DATE_FORMAT}, HH:mm`;

const CALENDAR_FORMAT = {
    lastDay: PRETTY_FORMAT,
    lastWeek: PRETTY_FORMAT,
    nextDay: PRETTY_FORMAT,
    nextWeek: PRETTY_FORMAT,
    sameElse: PRETTY_FORMAT,
};

const CALENDAR_DATE_FORMAT = {
    lastDay: DATE_FORMAT,
    lastWeek: DATE_FORMAT,
    nextDay: DATE_FORMAT,
    nextWeek: DATE_FORMAT,
    sameElse: DATE_FORMAT,
};

moment.updateLocale('en', {
    calendar: {
        sameDay: '[Today at] HH:mm',
    },
});

moment.updateLocale('de', {
    calendar: {
        sameDay: '[Heute um] HH:mm',
    },
});

export function prettyPrintTime(date: Date = new Date(), locale = 'en'): string {
    return moment(date).locale(locale).calendar(CALENDAR_FORMAT);
}

export function prettyPrintDate(date: Date = new Date(), locale = 'en'): string {
    return moment(date).locale(locale).calendar(CALENDAR_DATE_FORMAT);
}

export function getStartOfTheMonth(date: Date = new Date()): string {
    return moment()
        .year(date.getFullYear())
        .month(date.getMonth())
        .date(FIRST_DAY)
        .hour(0)
        .minute(0)
        .second(0)
        .millisecond(0)
        .toISOString();
}

export function getEndOfTheMonth(date: Date = new Date()): string {
    return moment()
        .year(date.getFullYear())
        .month(date.getMonth())
        .endOf('month')
        .hour(23)
        .minute(59)
        .second(59)
        .millisecond(999)
        .toISOString();
}

export function getMonthAndYear(date: Date = new Date()) {
    return moment(date).format('MMM YYYY');
}

export function getDay(date: Date = new Date()) {
    return moment(date).format('DD');
}

export function getMonthShort(date: Date = new Date()) {
    return moment(date).format('MMM').toUpperCase();
}

export function getMonthFull(date: Date = new Date()) {
    return moment(date).format('MMMM');
}

export function getDate(date: Date = new Date(), format = DATE_FORMAT) {
    return moment(date).format(format);
}

export function parseDate(date: string, format: string = DATE_FORMAT): Moment {
    return moment(date, format, true);
}
