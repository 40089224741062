import { useMutation, useQueryClient } from '@tanstack/react-query';
import { DeleteMutation, PostMutation, PutMutation } from '../../mutations';
import { QuestionnaireSectionRequest, QuestionRequest } from './question.dtos';

export const useCreateQuestionMutation = () => {
    const queryClient = useQueryClient();

    return useMutation(
        PostMutation<void, QuestionRequest>(`/api/dma/questions`),
        {
            onSuccess: () => queryClient.invalidateQueries(['dma', 'questions']),
        },
    );
};

export const useEditQuestionMutation = (questionId: number) => {
    const queryClient = useQueryClient();

    return useMutation(
        PutMutation<void, QuestionRequest>(`/api/dma/questions/${questionId}`),
        {
            onSuccess: () => queryClient.invalidateQueries(['dma', 'questions']),
        },
    );
};

export const useDeleteQuestionMutation = () => {
    const queryClient = useQueryClient();

    return useMutation(
        DeleteMutation<void, number>(`/api/dma/questions/`),
        {
            onSuccess: () => {
                void queryClient.invalidateQueries(['dma', 'questions']);
                void queryClient.invalidateQueries(['dma', 'questions', 'sections']);
            },
        },
    );
};

export const useCreateCustomQuestionnaireSectionMutation = () => {
    const queryClient = useQueryClient();

    return useMutation(
        PostMutation<void, QuestionnaireSectionRequest>(`/api/dma/questionnaire-sections`),
        {
            onSuccess: () => {
                void queryClient.invalidateQueries(['dma', 'questions']);
                void queryClient.invalidateQueries(['dma', 'questions', 'sections']);
                void queryClient.invalidateQueries(['dma', 'questionnaire-sections', 'custom']);
            },
        },
    );
};

export const useEditCustomQuestionnaireSectionMutation = () => {
    const queryClient = useQueryClient();

    return useMutation(
        PutMutation<void, QuestionnaireSectionRequest>(`/api/dma/questionnaire-sections`),
        {
            onSuccess: () => {
                void queryClient.invalidateQueries(['dma', 'questions']);
                void queryClient.invalidateQueries(['dma', 'questions', 'sections']);
                void queryClient.invalidateQueries(['dma', 'questionnaire-sections', 'custom']);
            },
        },
    );
};

export const useDeleteCustomQuestionnaireSectionMutation = () => {
    const queryClient = useQueryClient();

    return useMutation(
        DeleteMutation<void, void>(`/api/dma/questionnaire-sections`),
        {
            onSuccess: () => {
                void queryClient.invalidateQueries(['dma', 'questions']);
            },
        },
    );
};
