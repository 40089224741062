import { useMutation, useQueryClient } from '@tanstack/react-query';
import { DeleteMutation, PostMutation, PutMutation } from '../../mutations';
import { GetQuery } from '../../queries';
import { CreateCandidateRequest, UpdateCandidateRequest } from './candidate.dtos';

export const useCreateCandidateMutation = () => {
    const queryClient = useQueryClient();
    return useMutation(
        PostMutation<void, CreateCandidateRequest>(`/api/dpa/candidates`),
        {
            onSuccess: () => queryClient.invalidateQueries(['dpa', 'candidates']),
        },
    );
};

export const useUpdateCandidateMutation = (id: number) => {
    const queryClient = useQueryClient();
    return useMutation(
        PutMutation<void, UpdateCandidateRequest>(`/api/dpa/candidates/${id}`),
        {
            onSuccess: () => {
                void queryClient.invalidateQueries(['dpa', 'candidates']);
                void queryClient.invalidateQueries(['dpa', 'candidates', id]);
            },
        },
    );
};

export const useDeleteCandidateMutation = () => {
    const queryClient = useQueryClient();
    return useMutation(
        DeleteMutation<void, number>(`/api/dpa/candidates/`),
        {
            onSuccess: () => queryClient.invalidateQueries(['dpa', 'candidates']),
        },
    );
};

export const useSyncCandidatesMutation = () => {
    const queryClient = useQueryClient();
    return useMutation(
        GetQuery<void>(`/api/dpa/sync/candidates`),
        {
            onSuccess: () => void queryClient.invalidateQueries(['dpa', 'candidates']),
        },
    );
};
