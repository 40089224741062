import { apisauce } from './api';
import { ApiSuccessResponse } from './index';

export const GetQuery = <TResult = void>(url: string, params = {}): () => Promise<TResult> => {
    return async () => {
        const response: ApiSuccessResponse<TResult> = await apisauce.get(url, params);

        const {data} = response.data;

        return data;
    };
};
