import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { GetQuery } from '../../queries';
import { Assessment, AssessmentSubmissionAnswer } from './assessment.dtos';

export const useGetAssessmentsQuery = () => {
    return useQuery(
        ['dma', 'assessments'],
        GetQuery<Assessment[]>(`/api/dma/assessments`),
    );
};

export const useGetAssessmentByLinkQuery = (link: string, options: UseQueryOptions<Assessment> = {}) => {
    return useQuery<Assessment>(
        ['dma', 'assessment', link],
        GetQuery(`/api/dma/assessments/${link}`),
        options,
    );
};

export const useGetAssessmentResultsByLinkQuery = <T = AssessmentSubmissionAnswer[]>(link: string, options: UseQueryOptions<AssessmentSubmissionAnswer[], unknown, T> = {}) => {
    return useQuery<AssessmentSubmissionAnswer[], unknown, T>(
        ['dma', 'assessments', link, 'results'],
        GetQuery(`/api/dma/assessments/${link}/results`),
        options,
    );
};
