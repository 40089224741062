import create from 'zustand';
import { persist } from 'zustand/middleware';

export const useSettingsStore = create(persist<SettingsState>(
    (set) => ({
        language: 'en',
        setLanguage: (language: Locale) => set(() => ({language})),
    } as SettingsState),
    {
        name: 'settings',
    },
));

export type Locale = 'en' | 'de';

type SettingsState = {
    language: Locale;
    setLanguage: (locale: Locale) => void;
}
