import { AdminAppType } from '../../apps/admin/app';
import { usePathname } from '../use-pathname/use-pathname';

/**
 * Returns the currently active admin app or undefined
 * @return {AdminAppType} 'dma' or 'dpa'
 */
export const useAdminApp = (): AdminAppType => {
    const pathname = usePathname();
    return /^\/admin\/(d[mp]a).*/.exec(pathname)?.pop() as AdminAppType;
};
