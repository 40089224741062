import create from 'zustand';
import { Filtered } from '../../api/dtos';
import {CompanyDepartment, CompanyDivision, CompanyPosition} from '../../api/companies';
import {
    DmaAssessmentResultsBenchmarkOptions,
} from '../../apps/admin/components/dma/assessment-results/options/benchmarks-dropdown/assessment-results-options-benchmarks-dropdown';

export const useDmaAssessmentResultsOptionsStore = create<DmaAssessmentResultsOptionsState>(
    (set, get) => ({
        positions: [],
        departments: [],
        divisions: [],
        benchmarks: {
            kmu: true,
            dma: false,
        },
        autorefresh: false,
        infoLegend: true,
        positionsLegend: false,
        departmentsLegend: false,
        divisionsLegend: false,
        isRenderingChart: false,
        setPositions: (positions: CompanyPosition[]) => set({positions: positions.map(position => ({...position, show: true}))}),
        setDepartments: (departments: CompanyDepartment[]) => set({departments: departments.map(department => ({...department, show: true}))}),
        setDivisions: (divisions: CompanyDivision[]) => set({divisions: divisions.map(division => ({...division, show: true}))}),
        togglePosition: (position: Filtered<CompanyPosition>) => {
            position.show = !position.show;
            set({positions: [...get().positions]});
        },
        toggleDepartment: (department: Filtered<CompanyDepartment>) => {
            department.show = !department.show;
            set({departments: [...get().departments]});
        },
        toggleDivision: (division: Filtered<CompanyDivision>) => {
            division.show = !division.show;
            set({divisions: [...get().divisions]});
        },
        toggleKmuBenchmark: () => set({benchmarks: {...get().benchmarks, kmu: !get().benchmarks.kmu}}),
        toggleDmaBenchmark: () => set({benchmarks: {...get().benchmarks, kmu: !get().benchmarks.dma}}),
        toggleAutorefresh: () => set({autorefresh: !get().autorefresh}),
        toggleInfoLegend: () => set({infoLegend: !get().infoLegend}),
        togglePositionsLegend: () => set({positionsLegend: !get().positionsLegend}),
        toggleDepartmentsLegend: () => set({departmentsLegend: !get().departmentsLegend}),
        toggleDivisionsLegend: () => set({divisionsLegend: !get().divisionsLegend}),
        setIsRenderingChart: (isRenderingChart: boolean) => set({isRenderingChart}),
    } as DmaAssessmentResultsOptionsState),
);

type DmaAssessmentResultsOptionsState = {
    positions: Filtered<CompanyPosition>[];
    departments: Filtered<CompanyDepartment>[];
    divisions: Filtered<CompanyDivision>[];
    benchmarks: DmaAssessmentResultsBenchmarkOptions;
    autorefresh: boolean;
    infoLegend: boolean;
    positionsLegend: boolean;
    departmentsLegend: boolean;
    divisionsLegend: boolean;
    isRenderingChart: boolean;
    setPositions: (positions: CompanyPosition[]) => void;
    setDepartments: (departments: CompanyDepartment[]) => void;
    setDivisions: (divisions: CompanyDivision[]) => void;
    togglePosition: (position: Filtered<CompanyPosition>) => void;
    toggleDepartment: (position: Filtered<CompanyDepartment>) => void;
    toggleDivision: (position: Filtered<CompanyDivision>) => void;
    toggleKmuBenchmark: () => void;
    toggleDmaBenchmark: () => void;
    toggleAutorefresh: () => void;
    toggleInfoLegend: () => void;
    togglePositionsLegend: () => void;
    toggleDepartmentsLegend: () => void;
    toggleDivisionsLegend: () => void;
    setIsRenderingChart: (isRenderingChart: boolean) => void;
}
