import { Card, CardBody } from '@windmill/react-ui';
import React from 'react';
import { I18nextProviderProps, useTranslation } from 'react-i18next';

type Props = {
    title?: string;
    subtitle?: string;
    description: string;
    actionIcon?: React.ReactNode;
    tx?: string;
    txOptions?: I18nextProviderProps;
}

export const DescriptionCard: React.FC<Props> = (props) => {
    const {title = '', subtitle, description, actionIcon, tx, txOptions = {}} = props;

    const {t} = useTranslation();
    const translatedTitle = tx && t(tx, txOptions);

    return (
        <Card>
            <CardBody className="flex items-center">
                <div>
                    <p className="mb-2 text-xl font-semibold text-secondary dark:text-primary flex">
                        <span>{translatedTitle || title}</span>
                        {actionIcon && <span className="ml-3 mt-2">{actionIcon}</span>}
                    </p>
                    <p className="mb-1 text-sm font-semibold text-gray-700 dark:text-gray-200">{subtitle}</p>
                    <p className="text-sm font-medium text-gray-600 dark:text-gray-400">{description}</p>
                </div>
            </CardBody>
        </Card>
    );
};
