import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { usePathname } from '../../../hooks';

export const AccessibleNavigationAnnouncer: React.FC = () => {
    const [message, setMessage] = useState('');
    const pathname = usePathname();

    const {t} = useTranslation('accessibility');

    useEffect(() => {
        // ignore the /
        if (pathname.length > 1) {
            // make sure navigation has occurred and screen reader is ready
            setTimeout(() => setMessage(t('navigator.navigatedToPage', {route: pathname.slice(1)})), 500);
        } else {
            setMessage('');
        }
    }, [pathname, t]);

    return (
        <span className="sr-only" role="status" aria-live="polite" aria-atomic="true">
            {message}
        </span>
    );
};
