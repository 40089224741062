import { useToast } from '../use-toast/use-toast';
import { useTranslation } from 'react-i18next';

/**
 * Provides a method to copy text to the clipboard along with an info-toast on success.
 * @param txSuccess i18n translation-key for info-toast text
 */
export const useCopyToClipboard = (txSuccess = 'copied') => {
    const {infoToast} = useToast();
    const {t} = useTranslation();
    return (text: string) => {
        navigator.clipboard.writeText(text).then(() => {
            infoToast(t(txSuccess));
        });
    };
};
