import React from 'react';
import { LeafProps } from './leaf.props';

export const Leaf: React.FC<LeafProps> = ({attributes, children, leaf}) => {
    if (leaf.bold) {
        children = <strong>{children}</strong>;
    }

    if (leaf.italic) {
        children = <em>{children}</em>;
    }

    if (leaf.underline) {
        children = <u>{children}</u>;
    }

    if (leaf.link) {
        children = <a
            href={leaf.link}
            className={'link text-primary cursor-pointer hover:underline'}
            target="_blank"
            rel="noreferrer"
        >{children}</a>;
    }

    return <span {...attributes}>{children}</span>;
};
