import React from 'react';
import { useTranslation } from 'react-i18next';

import Image from '../../assets/img/404.svg';

export const Page404: React.FC = () => {
    const {t} = useTranslation('errors');

    return (
        <div className="flex flex-col items-center">
            <img src={Image} alt="404" className="mt-8 w-3/4 lg:w-1/4"/>
            <h1 className="text-3xl font-semibold text-gray-700 dark:text-gray-200 mt-4 mb-2">{t('404.title')}</h1>
            <p className="text-gray-700 dark:text-gray-300">
                {t('404.description')}
            </p>
        </div>
    );
};
