import { useEffect } from 'react';

type EventType = 'copy' | 'cut' | 'paste'

export const usePreventCopyPaste = (win: Window, actions: [EventType?, EventType?, EventType?]) => {
    useEffect(() => {
        actions?.forEach((action) => {
            action && win.addEventListener(action, preventAction);
        });
        return () => {
            actions.forEach((action) => {
                action && win.removeEventListener(action, preventAction);
            });
        };
    }, [win, actions]);

    const preventAction = (e: ClipboardEvent) => {
        e.preventDefault();
        return false;
    };
};
