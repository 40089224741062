import create from 'zustand';
import { persist, PersistOptions } from 'zustand/middleware';
import { StateCreator } from 'zustand/esm/vanilla';
import { SortableTableConfig } from '../../components/tables/sortable-table/sortable-table-config';
import { SortableTableSortConfig } from '../../hooks';

export type TableConfigState = SortableTableConfig & {
    setPage: (page: number) => void;
    setRowsPerPage: (rowsPerPage: number) => void;
    setSortConfig: (sortConfig: TableSortConfigState) => void;
}

export type TableSortConfigState = SortableTableSortConfig;

const tableConfigStateInitializer: StateCreator<TableConfigState> = (set) => ({
    page: 1,
    rowsPerPage: 10,
    sortConfig: {},
    setPage: (page: number) => set({page}),
    setRowsPerPage: (rowsPerPage: number) => set({rowsPerPage}),
    setSortConfig: (sortConfig: TableSortConfigState) => set({sortConfig}),
});

const tableConfigPersistOptions: Omit<PersistOptions<TableConfigState, Partial<TableConfigState>>, 'name'> = {
    partialize: (state: TableConfigState) => ({
        rowsPerPage: state.rowsPerPage,
        sortConfig: state.sortConfig,
    }),
};

export const useUsersTableConfigStore = create(persist(
    tableConfigStateInitializer,
    {
        name: 'users-table-config',
        ...tableConfigPersistOptions,
    },
));

export const useCompaniesTableConfigStore = create(persist(
    tableConfigStateInitializer,
    {
        name: 'companies-table-config',
        ...tableConfigPersistOptions,
    },
));

export const useDmaAssessmentsTableConfigStore = create(persist(
    tableConfigStateInitializer,
    {
        name: 'dma-assessments-table-config',
        ...tableConfigPersistOptions,
    },
));

export const useDpaAssessmentResultsTableConfigStore = create(persist(
    tableConfigStateInitializer,
    {
        name: 'dpa-assessment-results-table-config',
        ...tableConfigPersistOptions,
    },
));

export const useDpaCandidatesTableConfigStore = create(persist(
    tableConfigStateInitializer,
    {
        name: 'dpa-candidates-table-config',
        ...tableConfigPersistOptions,
    },
));
