import { useMutation, UseMutationOptions, useQueryClient } from '@tanstack/react-query';
import { DeleteMutation, PostMutation, PutMutation } from '../mutations';
import { CreateUserRequest, ForgotPasswordRequest, LoginUserRequest, ResetPasswordRequest, UpdateUserRequest, User } from './user.dtos';
import { apisauce } from '../api';
import { ApiSuccessResponse } from '../index';

export const useLoginMutation = () => {
    return useMutation(
        async (payload: LoginUserRequest) => {
            const loginResponse = await apisauce.post<User>(`/login`, payload);

            const {id: userId} = loginResponse.data;

            const userResponse: ApiSuccessResponse<User> = await apisauce.get(`/api/users/${userId}`);

            const {data} = userResponse.data;

            return data;
        },
    );
};

export const useLogoutMutation = (options: UseMutationOptions<void> = {}) => {
    return useMutation(
        async () => {
            await apisauce.post<User>(`/logout`);
        },
        options,
    );
};

export const useForgotPasswordMutation = (options: UseMutationOptions<void, unknown, ForgotPasswordRequest> = {}) => {
    return useMutation(
        PostMutation(`/api/users/forgot-password`),
        options,
    );
};

export const useResetPasswordMutation = (options: UseMutationOptions<User, unknown, ResetPasswordRequest> = {}) => {
    return useMutation(
        PostMutation(`/api/users/reset-password`),
        options,
    );
};

export const useCreateUserMutation = (options: UseMutationOptions<User, unknown, CreateUserRequest> = {}) => {
    const queryClient = useQueryClient();
    return useMutation(
        PostMutation(`/api/users`),
        {
            ...options,
            onSuccess: (data, variables, context) => {
                options?.onSuccess?.(data, variables, context);
                void queryClient.invalidateQueries(['users']);
            },
        },
    );
};

export const useUpdateUserMutation = (userId: number, options: UseMutationOptions<User, unknown, UpdateUserRequest> = {}) => {
    const queryClient = useQueryClient();
    return useMutation(
        PutMutation(`/api/users/${userId}`),
        {
            ...options,
            onSuccess: (data, variables, context) => {
                options?.onSuccess?.(data, variables, context);
                void queryClient.invalidateQueries(['users']);
            },
        },
    );
};

export const useDeleteUserMutation = (options: UseMutationOptions<void, unknown, number> = {}) => {
    const queryClient = useQueryClient();
    return useMutation(
        DeleteMutation(`/api/users/`),
        {
            ...options,
            onSuccess: (data, variables, context) => {
                options?.onSuccess?.(data, variables, context);
                void queryClient.invalidateQueries(['users']);
            },
        },
    );
};

export const useUploadProfileImageMutation = (options: UseMutationOptions<User, unknown, FormData> = {}) => {
    return useMutation(
        PostMutation(`/api/users/upload-profile-image`),
        {...options},
    );
};
