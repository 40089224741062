import classNames from 'classnames';
import React from 'react';
import { PageTitleProps } from './page-title.props';

export const PageTitle: React.FC<PageTitleProps> = (props) => {
    const {
        marginClass = 'my-6',
        textClass = 'text-2xl font-semibold text-gray-700 dark:text-gray-200',
        children,
    } = props;

    const cls = classNames(marginClass, textClass);
    return (
        <h1 className={cls}>{children}</h1>
    );
};
