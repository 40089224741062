import classNames from 'classnames';
import React from 'react';
import { RoundIconProps } from './round-icon.props';

export const RoundIcon: React.FC<RoundIconProps> = ({
                                                        icon: Icon,
                                                        iconColorClass = 'text-primary dark:text-teal-100',
                                                        bgColorClass = 'bg-teal-100 dark:bg-primary',
                                                        className,
                                                    }) => {
    const baseStyle = 'p-3 rounded-full';

    const cls = classNames(baseStyle, iconColorClass, bgColorClass, className);
    return (
        <div className={cls}>
            <Icon className="w-5 h-5"/>
        </div>
    );
};
