import React from 'react';
import { PageTitle } from '../../../../typography/page-title/page-title';
import { SectionTitle } from '../../../../typography/section-title/section-title';
import { ElementProps } from './element.props';

const BOTTOM_MARGIN = 'mb-2';

export const Element: React.FC<ElementProps> = ({attributes, children, element}) => {
    switch (element.type) {
        case 'block-quote':
            return <blockquote
                className={`${BOTTOM_MARGIN} ml-1 p-1 pl-2 border-l-4 text-gray-500 italic text-base`} {...attributes}>{children}</blockquote>;
        case 'bulleted-list':
            return <ul className={`${BOTTOM_MARGIN} list-disc ml-8`} {...attributes}>{children}</ul>;
        case 'list-item':
            return <li className={`pl-1`} {...attributes}>{children}</li>;
        case 'numbered-list':
            return <ol className={`${BOTTOM_MARGIN} list-decimal ml-8`} {...attributes}>{children}</ol>;
        case 'title':
            return <PageTitle marginClass={'my-2'}>{children}</PageTitle>;
        case 'subtitle':
            return <SectionTitle marginClass={'my-2'}>{children}</SectionTitle>;
        default:
            return <p className={BOTTOM_MARGIN} {...attributes}>{children}</p>;
    }
};
