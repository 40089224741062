import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { QuestionnaireSection, ShallowQuestionnaireSection } from './question.dtos';
import { GetQuery } from '../../index';
import { useCallback } from 'react';

export const useGetQuestionsQuery = <TData = QuestionnaireSection[]>(options: UseQueryOptions<QuestionnaireSection[], unknown, TData> = {}) => {
    const transformQuestionsData = useCallback((data: QuestionnaireSection[]) => {
        let order = 1;
        data
            .sort((qs1, qs2) => qs1.order - qs2.order)
            .forEach(item => {
                item.questions.forEach(question => {
                    question.order = order++;
                });
            });
        return options.select ? options.select(data) : data as TData;
    }, [options.select]); // eslint-disable-line react-hooks/exhaustive-deps
    return useQuery<QuestionnaireSection[], unknown, TData>(
        ['dma', 'questions'],
        GetQuery(`/api/dma/questions`),
        {
            ...options,
            select: transformQuestionsData,
        },
    );
};

export const useGetQuestionsByAssessmentLinkQuery = <TData = QuestionnaireSection[]>(link: string, options: UseQueryOptions<QuestionnaireSection[], unknown, TData> = {}) => {
    const transformQuestionsData = useCallback((data: QuestionnaireSection[]) => {
        let order = 1;
        data
            .sort((qs1, qs2) => qs1.order - qs2.order)
            .forEach(item => {
                item.questions.forEach(question => {
                    question.order = order++;
                });
            });
        return options.select ? options.select(data) : data as TData;
    }, [options.select]); // eslint-disable-line react-hooks/exhaustive-deps
    return useQuery<QuestionnaireSection[], unknown, TData>(
        ['dma', 'questions', link],
        GetQuery(`/api/dma/questions/assessment/${link}`),
        {
            ...options,
            select: transformQuestionsData,
        },
    );
};

export const useGetQuestionsSectionsQuery = <TData = ShallowQuestionnaireSection[]>(options: UseQueryOptions<ShallowQuestionnaireSection[], unknown, TData> = {}) => {
    return useQuery<ShallowQuestionnaireSection[], unknown, TData>(
        ['dma', 'questions', 'sections'],
        GetQuery(`/api/dma/questions/sections`),
        options,
    );
};

export const useGetCustomQuestionnaireSectionQuery = (options: UseQueryOptions<QuestionnaireSection> = {}) => {
    return useQuery<QuestionnaireSection>(
        ['dma', 'questionnaire-sections', 'custom'],
        GetQuery(`/api/dma/questionnaire-sections/custom`),
        options,
    );
};
