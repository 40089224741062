import { Button } from '@windmill/react-ui';
import React from 'react';
import { ChevronLeftSmallIcon, ChevronRightSmallIcon } from '../../../../assets/icons';
import { ButtonAsButtonProps } from '@windmill/react-ui/dist/Button';

type NavigationButtonProps = ButtonAsButtonProps & {
    directionIcon: 'prev' | 'next';
}

export const NavigationButton: React.FC<NavigationButtonProps> = (props) => {
    const {onClick, disabled, directionIcon} = props;
    const ariaLabel = directionIcon === 'prev' ? 'Previous' : 'Next';

    const Icon = directionIcon === 'prev' ? ChevronLeftSmallIcon : ChevronRightSmallIcon;

    return (
        <Button
            size="small"
            layout="link"
            icon={() => <Icon />}
            onClick={onClick}
            disabled={disabled}
            aria-label={ariaLabel}
        />
    );
};
