import { Card, CardBody } from '@windmill/react-ui';
import React from 'react';
import { I18nextProviderProps, useTranslation } from 'react-i18next';

type ValueCardProps = {
    title?: string;
    value: any;
    tx?: string;
    txOptions?: I18nextProviderProps;
}

export const ValueCard: React.FC<ValueCardProps> = (props) => {
    const {title = '', value, tx, txOptions = {}, children: icon} = props;

    const {t} = useTranslation();
    const translatedTitle = tx && t(tx, txOptions);

    return (
        <Card>
            <CardBody className="flex items-center">
                {icon}
                <div>
                    <p className="mb-2 text-sm font-medium text-gray-600 dark:text-gray-400">{translatedTitle || title}</p>
                    <p className="text-lg font-semibold text-gray-700 dark:text-gray-200">{value}</p>
                </div>
            </CardBody>
        </Card>
    );
};
