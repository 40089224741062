import React from 'react';
import { ToastContainer } from 'react-toastify';

export const ThemedToast: React.FC = () => {
    return (
        <ToastContainer
            hideProgressBar={true}
            closeOnClick={true}
            pauseOnHover={true}
            closeButton={false}
            className="mb-1"
            toastClassName={() => 'relative flex rounded-md justify-between overflow-hidden cursor-pointer mb-1'}
            bodyClassName={() => 'text-sm font-medium block'}
        />
    );
};
