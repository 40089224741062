import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavigationButton } from './navigation-button/navigation-button';
import { EmptyPageButton } from './empty-page-button/empty-page-button';
import { PageButton } from './page-button/page-button';

type AppPaginationProps = {
    /**
     * The total number of results
     */
    totalResults: number;
    /**
     * The number of results shown per page
     */
    resultsPerPage?: number;
    /**
     * The accessible name of the pagination (what does it refer to?)
     */
    label: string;
    /**
     * The current active page
     */
    activePage?: number;
    /**
     * The function executed on page change
     */
    onChange: (activePage: number) => void;
}

export const Pagination: React.FC<AppPaginationProps> = (props) => {
    const {totalResults, resultsPerPage = 10, label, activePage: page = 1, onChange, ...other} = props;
    const [pages, setPages] = useState<(number | string)[]>([]);
    const [activePage, setActivePage] = useState(page);
    const {t} = useTranslation();

    const TOTAL_PAGES = Math.ceil(totalResults / resultsPerPage);
    const FIRST_PAGE = 1;
    const LAST_PAGE = TOTAL_PAGES;
    const MAX_VISIBLE_PAGES = 7;

    function handlePreviousClick() {
        setActivePage(activePage - 1);
    }

    function handleNextClick() {
        setActivePage(activePage + 1);
    }

    useEffect(() => {
        // [1], 2, 3, 4, 5, ..., 12 case #1
        // 1, [2], 3, 4, 5, ..., 12
        // 1, 2, [3], 4, 5, ..., 12
        // 1, 2, 3, [4], 5, ..., 12
        // 1, ..., 4, [5], 6, ..., 12 case #2
        // 1, ..., 5, [6], 7, ..., 12
        // 1, ..., 6, [7], 8, ..., 12
        // 1, ..., 7, [8], 9, ..., 12
        // 1, ..., 8, [9], 10, 11, 12 case #3
        // 1, ..., 8, 9, [10], 11, 12
        // 1, ..., 8, 9, 10, [11], 12
        // 1, ..., 8, 9, 10, 11, [12]
        // [1], 2, 3, 4, 5, ..., 8
        // always show first and last
        // max of 7 components shown (incl. [...])
        if (TOTAL_PAGES <= MAX_VISIBLE_PAGES) {
            setPages(Array.from({length: TOTAL_PAGES}).map((_, i) => i + 1));
        } else if (activePage < 5) {
            // #1 active page < 5 -> show first 5
            setPages([1, 2, 3, 4, 5, '...', TOTAL_PAGES]);
        } else if (activePage >= 5 && activePage < TOTAL_PAGES - 3) {
            // #2 active page >= 5 && < TOTAL_PAGES - 3
            setPages([1, '...', activePage - 1, activePage, activePage + 1, '...', TOTAL_PAGES]);
        } else {
            // #3 active page >= TOTAL_PAGES - 3 -> show last
            setPages([
                1,
                '...',
                TOTAL_PAGES - 4,
                TOTAL_PAGES - 3,
                TOTAL_PAGES - 2,
                TOTAL_PAGES - 1,
                TOTAL_PAGES,
            ]);
        }
    }, [activePage, TOTAL_PAGES]);

    useEffect(() => {
        onChange(activePage);
    }, [activePage, onChange]);

    return (
        <div
            className={'flex flex-col justify-between text-xs sm:flex-row text-gray-600 dark:text-gray-400'} {...other}>
      <span className="flex items-center font-semibold tracking-wide uppercase">
        {t('pagination.infoText', {
            min: activePage * resultsPerPage - resultsPerPage + 1,
            max: Math.min(activePage * resultsPerPage, totalResults),
            total: totalResults,
        })}
      </span>

            <div className="flex mt-2 sm:mt-auto sm:justify-end">
                <nav aria-label={label}>
                    <ul className="inline-flex items-center">
                        <li>
                            <NavigationButton
                                directionIcon="prev"
                                disabled={activePage === FIRST_PAGE}
                                onClick={handlePreviousClick}
                            />
                        </li>
                        {pages.map((p, i) => (
                            <li key={p.toString() + i}>
                                {p === '...' ? (
                                    <EmptyPageButton />
                                ) : (
                                    <PageButton
                                        page={p}
                                        isActive={p === activePage}
                                        onClick={() => setActivePage(+p)}
                                    />
                                )}
                            </li>
                        ))}
                        <li>
                            <NavigationButton
                                directionIcon="next"
                                disabled={activePage === LAST_PAGE}
                                onClick={handleNextClick}
                            />
                        </li>
                    </ul>
                </nav>
            </div>
        </div>
    );
};
