import { QueryClient } from '@tanstack/react-query';
import { ApiResponse as ApisauceResponse } from 'apisauce';
import { ApiErrorKind } from './api.errors';

export type ApiSuccessResponse<TData> = ApisauceResponse<{ data: TData, status: number }>;

export type ApiErrorResponse = {
    kind?: ApiErrorKind;
    message?: string;
}

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            staleTime: 30 * 1000,
        },
    },
});

export * from './queries';
export * from './mutations';
export * from './api';
export * from './api.errors';
export * from './api.interceptors';

export default queryClient;
