import { useLocation } from 'react-router';

/**
 * Returns current location pathname
 * @return {string} pathname
 */
export const usePathname = (): string => {
    const location = useLocation();
    return location.pathname;
};
