import React, { useEffect, useRef } from 'react';
import { AnimatedIcon } from '../../elements/animated-icon/animated-icon';
import { SectionTitle } from '../../typography/section-title/section-title';

export const AppLoader: React.FC<{ hideLoadingText?: boolean }> = ({hideLoadingText = false}) => {
    const animationRef = useRef(undefined);

    useEffect(() => {
        animationRef.current.setSpeed(1.8);
    }, []);

    return (
        <div className="w-full h-screen flex flex-col items-center justify-center bg-gray-50 dark:bg-gray-900">
            <AnimatedIcon lottieRef={animationRef} icon={'pageLoading'} size={500} className={'-mt-40'}/>
            {!hideLoadingText && <SectionTitle className={'-mt-40'}>Loading...</SectionTitle>}
        </div>
    );
};
