import create from 'zustand';
import { persist } from 'zustand/middleware';

export const useThemeStore = create(persist(
    (set) => ({
        theme: 'light',
        toggleTheme: () => set((state) => ({theme: state.theme === 'light' ? 'dark' : 'light'})),
    } as ThemeState),
    {
        name: 'theme',
    },
));

type ThemeState = {
    theme: 'light' | 'dark';
    toggleTheme: () => void;
}
