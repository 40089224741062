import { ApiResponse, ApisauceInstance } from 'apisauce';
import { AxiosRequestConfig } from 'axios';
import { useErrorsStore } from '../stores';
import { convertToApiError } from './api.errors';
import { CSRF_TOKEN } from './api';

const withInterceptors = (apisauce: ApisauceInstance): ApisauceInstance => {
    apisauce.addAsyncRequestTransform(request => onRequestVerifyCsrfToken(apisauce, request));
    apisauce.addResponseTransform(onResponseHandleError);
    return apisauce;
};

const onRequestVerifyCsrfToken = async (apisauce: ApisauceInstance, request: AxiosRequestConfig): Promise<void> => {
    // If http method is `post | put | delete` and XSRF-TOKEN cookie is
    // not present, call '/sanctum/csrf-cookie' to set CSRF token
    if (['POST', 'PUT', 'DELETE'].includes(request.method.toUpperCase()) &&
        !document.cookie.includes(CSRF_TOKEN)) {
        await apisauce.get('/sanctum/csrf-cookie');
    }
};

const onResponseHandleError = (response: ApiResponse<any>): void => {
    if (!response.ok) {
        const error = convertToApiError(response);
        if (error?.kind === 'unauthorized') {
            useErrorsStore.getState().setForceLogout(true);
        }
        throw error;
    }
};

export default withInterceptors;
