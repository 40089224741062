import { useParams } from 'react-router-dom';

/**
 * Returns query params with values typed as number or string.
 * @return typed query params
 */
export const useTypedParams = <Params extends { [K in keyof Params]?: (string | number) } = {}>(): Params => {
    const params = useParams<Record<keyof Params, string>>();
    const typedParams = {};
    Object.entries(params).forEach(([k, v]) => {
        typedParams[k] = Number(v) == v ? Number(v) : v; // eslint-disable-line eqeqeq
    });
    return typedParams as Params;
};
