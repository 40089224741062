import { TableConfigState, TableSortConfigState } from '../../stores';
import { useState } from 'react';

/**
 * Fallback if a SortableTable isn't provided with a Zustand-store for table-config.
 * @return TableConfigState
 */
export const useLocalTableConfigStore = (): TableConfigState => {
    const [page, setPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [sortConfig, setSortConfig] = useState<TableSortConfigState>({});
    return {page, rowsPerPage, sortConfig, setPage, setRowsPerPage, setSortConfig};
};
