import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { GetQuery } from '../../queries';
import { Candidate } from './candidate.dtos';

export const useGetCandidatesQuery = <TData = Candidate[]>(options: UseQueryOptions<Candidate[], unknown, TData> = {}) => {
    return useQuery(
        ['dpa', 'candidates'],
        GetQuery<Candidate[]>(`/api/dpa/candidates`),
        options,
    );
};

export const useGetCandidateQuery = (id: number, options: UseQueryOptions<Candidate> = {}) => {
    return useQuery(
        ['dpa', 'candidates', id],
        GetQuery<Candidate>(`/api/dpa/candidates/${id}`),
        options,
    );
};
