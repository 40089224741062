import { Button, Input } from '@windmill/react-ui';
import React, { useState } from 'react';
import { useSlate } from 'slate-react';
import { Modal } from '../../../../elements/modal/modal';
import { Span } from '../span/span';
import { MarkButtonProps } from './mark-button.props';

export const MarkButton: React.FC<MarkButtonProps> = (props) => {
    const editor = useSlate();

    const {
        format, icon, isActive, toggle, currentSelection, showPopup = false, popupCallback = () => {
        },
    } = props;

    const [urlValue, setUrlValue] = useState(currentSelection && showPopup ? currentSelection.url : '');

    const handleSubmit = () => {
        // TODO: Bug, slate editor loses focus when URL input is focused...
        popupCallback(editor, format, urlValue);
        setUrlValue('');
    };

    return (
        <div className="relative">
            <Span
                active={isActive(editor, format)}
                icon={icon}
                onMouseDown={event => {
                    event.preventDefault();
                    toggle(editor, format);
                }}
            />

            {format === 'link' &&
                <div className="w-11/12">
                    <Modal
                        isOpen={showPopup}
                        hasFooter={false}
                        headerTx={'Set URL'}
                        onClose={handleSubmit}
                    >
                        <div className="flex space-x-1">
                            <Input
                                css=""
                                name="url"
                                onChange={e => setUrlValue(e.target.value)}
                                value={urlValue}
                                placeholder="Enter URL here"
                            />
                            <Button className="h-auto" size="regular" onClick={handleSubmit}>
                                Submit
                            </Button>
                        </div>
                    </Modal>
                </div>
            }
        </div>
    );
};
