import create from 'zustand';

export const useErrorsStore = create<ErrorsState>(
    (set) => ({
        forceLogout: false,
        setForceLogout: (forceLogout: boolean) => set({forceLogout}),
    } as ErrorsState),
);

type ErrorsState = {
    forceLogout: boolean;
    setForceLogout: (forceLogout: boolean) => void;
}
