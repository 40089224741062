import { create } from 'apisauce';
import withInterceptors from './api.interceptors';

export const CSRF_TOKEN = 'XSRF-TOKEN';

export const apisauce = withInterceptors(create({
    baseURL: `${process.env.REACT_APP_API_URL}`,
    timeout: 10 * 1000,
    withCredentials: true,
    headers: {
        Accept: 'application/json',
        // 'Api-Token': `${process.env.REACT_APP_DMA_API_URL}`, // TODO: Use Api Token for each request. Middleware needed on Laravel side
        'X-Requested-With': 'XMLHttpRequest',
    },
}));
