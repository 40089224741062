import { apisauce, ApiSuccessResponse } from './index';

export const PostMutation = <TResult, TPayload>(url: string): (payload?: TPayload) => Promise<TResult> => {
    return async (payload?: TPayload) => {
        const response: ApiSuccessResponse<TResult> = await apisauce.post(url, payload);

        const {data} = response.data;

        return data;
    };
};

export const PutMutation = <TResult, TPayload>(url: string): (payload?: TPayload) => Promise<TResult> => {
    return async (payload?: TPayload) => {
        const response: ApiSuccessResponse<TResult> = await apisauce.put(url, payload);

        const {data} = response.data;

        return data;
    };
};

export const DeleteMutation = <TResult = void, TIdentifier = void>(url: string): (identifier?: TIdentifier) => Promise<TResult> => {
    return async (identifier?: TIdentifier) => {
        const response: ApiSuccessResponse<TResult> = await apisauce.delete(url + (identifier || ''));

        const {data} = response.data;

        return data;
    };
};
