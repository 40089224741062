import Tippy from '@tippyjs/react';
import React from 'react';
import { InformationCircleSmallIcon } from '../../assets/icons';

const Tooltip: React.FC<{ text: string }> = ({text}) => {
    return (
        <span className="text-xs text-white bg-black dark:text-black dark:bg-white bg-opacity-75 block p-2 rounded-lg">
            {text}
        </span>);
};

export const InfoIcon: React.FC<{ tooltip: string }> = ({tooltip}) => {
    return (
        <span className="inline-block ml-1">
            <Tippy content={<Tooltip text={tooltip}/>}>
                <span className="block text-xs text-primary">
                    <InformationCircleSmallIcon/>
                </span>
            </Tippy>
        </span>
    );
};
