import React, { useCallback, useState } from 'react';
import { Question, QuestionnaireSection, useDeleteQuestionMutation, useGetQuestionsQuery } from '../../../../../api/dma';
import { DescriptionCard, Modal, SortableTable, TableHeaderProps } from '../../../../../components';
import { useDmaQuestionsTableSearchStore, useSettingsStore } from '../../../../../stores';
import { PermissionMatcher, useAuth } from '../../../../../hooks/use-auth/use-auth';
import { PermissionAction } from '../../../../../api/users';
import { CustomQuestionModal } from './custom-question-modal/custom-question-modal';
import { EditIcon, PlusIcon } from '../../../../../assets/icons';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { DEFAULT_SECTIONS_COUNT } from './questions-list';
import { useDisclosure } from '@mantine/hooks';

type TranslationQuestionnaireSection = Omit<QuestionnaireSection, 'questions'> & { questions: TranslatedQuestion[] };

type TranslatedQuestion = Question & { text: string }

const permissionToWriteQuestions: PermissionMatcher = {app: 'DMA', resource: 'QUESTIONS', action: PermissionAction.WRITE};

const tableFields: TableHeaderProps<TranslatedQuestion>[] = [
    {field: 'order', highlight: true},
    {field: 'text', notSortable: true},
    {field: 'created_at', dataType: 'date'},
    {field: 'updated_at', dataType: 'date'},
];

const QuestionsSectionList: React.FC = () => {
    const {t} = useTranslation();
    const {language} = useSettingsStore();
    const {sectionIndex} = useDmaQuestionsTableSearchStore();
    const {hasPermission} = useAuth();
    const transformQuestionsData = useCallback((data: QuestionnaireSection[]) => data.map(item => ({
        ...item,
        questions: item.questions.map(question => ({...question, text: question['text_' + language]} as TranslatedQuestion)),
    } as TranslationQuestionnaireSection)), [language]);
    const {data: questionnaireSections, isLoading} = useGetQuestionsQuery<TranslationQuestionnaireSection[]>({
        select: transformQuestionsData,
    });
    const {mutate: deleteQuestion, isLoading: isDeleting} = useDeleteQuestionMutation();
    const section = questionnaireSections[sectionIndex];
    const [questionToEdit, setQuestionToEdit] = useState<number | undefined>();
    const [editQuestionModalOpened, editQuestionModal] = useDisclosure(false);

    const onCloseModal = () => {
        setQuestionToEdit(undefined);
        editQuestionModal.close();
    };
    const onEditQuestion = (questionId: number) => {
        setQuestionToEdit(questionId);
        editQuestionModal.open();
    };
    const isCustomSection = sectionIndex >= DEFAULT_SECTIONS_COUNT;

    return (<>
        <div className="mb-3">
            <DescriptionCard
                title={section.title}
                subtitle={section['subtitle_' + language]}
                description={section['description_' + language]}
                actionIcon={isCustomSection && (
                    <Link to="questions/edit">
                        <EditIcon className="w-4 h-4 text-gray-300 hover:text-secondary cursor-pointer" />
                    </Link>
                )}
            />
        </div>
        <SortableTable
            data={section.questions}
            tableHeader={tableFields}
            txPrefix="questions.fields"
            readableRowIdentifier={(row) => row.order + '. ' + row.text}
            sortConfig={{key: 'number', direction: 'ascending'}}
            isLoading={isLoading || isDeleting}
            // TODO ms: restrict deleting/editing in case of non-expired assessment where include_custom_section = true?
            onDeletePress={section.company_id && section.questions.length > 1 && hasPermission(permissionToWriteQuestions) ? ({id}) => deleteQuestion(id) : undefined}
            onEditPress={hasPermission(permissionToWriteQuestions) ? ({id}) => onEditQuestion(id) : undefined}
            headerOption={isCustomSection && hasPermission(permissionToWriteQuestions) && ({
                title: t('questions.createQuestion'),
                icon: <PlusIcon />,
                action: editQuestionModal.open,
            })}
            useTableSearchStore={useDmaQuestionsTableSearchStore}
        />

        <Modal
            isOpen={editQuestionModalOpened}
            hasFooter={false}
            headerTx={'questions.questionModal.title' + (!questionToEdit ? 'Create' : 'Edit')}
            onClose={onCloseModal}
        >
            <CustomQuestionModal
                question={questionToEdit ? section.questions.find(q => q.id === questionToEdit) : undefined}
                onClose={onCloseModal}
            />
        </Modal>
    </>);
};

export default QuestionsSectionList;
