import { Button } from '@windmill/react-ui';
import React from 'react';
import { ButtonAsButtonProps } from '@windmill/react-ui/dist/Button';

type PageButtonProps = ButtonAsButtonProps & {
    /**
     * The page the button represents
     */
    page: string | number;
    /**
     * Defines if the button is active
     */
    isActive?: boolean;
}

export const PageButton: React.FC<PageButtonProps> = (props) => {
    const {page, isActive, onClick} = props;

    return (
        <Button size="pagination" layout={isActive ? 'primary' : 'link'} onClick={onClick}>
            {page}
        </Button>
    );
};
