import { useQuery } from '@tanstack/react-query';
import { GetQuery } from '../queries';
import { Role, User } from './user.dtos';
import { UseQueryOptions } from '@tanstack/react-query/src/types';

export const useGetUsersQuery = (options: UseQueryOptions<User[]> = {}) => {
    return useQuery(
        ['users'],
        GetQuery<User[]>(`/api/users`),
        {...options},
    );
};

export const useGetUserQuery = (userId: number, options: UseQueryOptions<User> = {}) => {
    return useQuery(
        ['users', userId],
        GetQuery<User>(`/api/users/${userId}`),
        {...options},
    );
};

export const useResetPasswordQuery = (token: string, options: UseQueryOptions = {}) => {
    return useQuery(
        ['users', 'reset-password', token],
        GetQuery(`/api/users/reset-password`, {token}),
        {
            ...options,
            retry: false,
            refetchOnMount: false,
            refetchOnWindowFocus: false,
            refetchOnReconnect: false,
            refetchIntervalInBackground: false,
            staleTime: Infinity,
            cacheTime: Infinity,
        },
    );
};

export const useRolesQuery = (options: UseQueryOptions<Role[]> = {}) => {
    return useQuery(
        ['roles'],
        GetQuery<Role[]>(`/api/roles`),
        {
            cacheTime: Infinity,
            staleTime: Infinity,
            ...options,
        },
    );
};
