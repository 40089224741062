import React from 'react';
import { SpanProps } from './span.props';

export const Span: React.FC<SpanProps> = ({className, active, icon: Icon, ...props}) => {
    return (
        <span
            {...props}
            className={`${className} cursor-pointer`}
        >
      <Icon className={`fill-current ${active ? 'text-gray-800' : 'text-gray-400'}`}/>
    </span>
    );
};
