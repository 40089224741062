import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { PostMutation, PutMutation } from '../../mutations';
import { SendResultRequest, UpdateCommentRequest } from './assessment-results.dtos';

export const useSendResultMutation = (options: UseMutationOptions<unknown, unknown, SendResultRequest> = {}) => {
    return useMutation(
        PostMutation<unknown, SendResultRequest>(`/api/dpa/results/send`),
        options,
    );
};

export const useUpdateCommentMutation = (resultId: number, options: UseMutationOptions<unknown, unknown, UpdateCommentRequest> = {}) => {
    return useMutation(
        PutMutation<unknown, UpdateCommentRequest>(`/api/dpa/results/${resultId}`),
        options,
    );
};
