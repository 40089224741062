import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { InformationCircleIcon } from '../../../assets/icons';
import { InfoTextProps } from './info-text.props';

export const InfoText: React.FC<InfoTextProps> = (props) => {
    const {className, textTx} = props;
    const baseStyle = 'flex flex-wrap text-gray-400 dark:text-gray-500 mb-5';

    const {t} = useTranslation();

    const cls = classNames(baseStyle, className);

    return (
        <div className={cls}>
            <InformationCircleIcon className={'w-5 h-5 mr-2'}/>
            <p className={'text-sm'}>{t(textTx)}</p>
        </div>
    );
};
