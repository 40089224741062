import create from 'zustand';
import { persist } from 'zustand/middleware';
import { Assessment, AssessmentByLink, AssessmentLinkDetails, Candidate, Question, Questionnaire, QuestionnaireSection } from '../../api/dpa';

export const useDpaAssessmentStore = create(persist<DpaAssessmentState>(
    (set, get) => ({
        questionnaire: {sections: []},
        setAnswer: (sectionId: number, questionId: number, answerValue: string) => {
            const questionnaire = get().questionnaire;
            questionnaire.sections
                .find(({id}) => id === sectionId).questions
                .find(({id}) => id === questionId).answer_value = answerValue;
            set({questionnaire: {...questionnaire}});
        },
        initializeAssessment: (assessmentByLink: AssessmentByLink) => {
            set({
                assessmentLinkDetails: {...assessmentByLink},
                assessment: assessmentByLink.assessment,
                candidate: assessmentByLink.candidate,
            });
        },
        updateAssessment: (candidate: Candidate, questionnaire: Questionnaire) => {
            set({
                questionnaire: questionnaire,
                candidate: candidate,
            });
        },
    } as DpaAssessmentState),
    {
        name: 'dpa-assessment',
        getStorage: () => sessionStorage,
    },
));

type DpaAssessmentState = {
    assessment: Omit<Assessment, 'questionnaire' | 'questionnaire_id' | 'link_details' | 'candidate'>;
    questionnaire: AnsweredDpaAssessmentQuestionnaire;
    assessmentLinkDetails: AssessmentLinkDetails;
    candidate: Candidate;
    setAnswer: (sectionIndex: number, questionId: number, answerValue: string) => void;
    initializeAssessment: (assessment: AssessmentByLink) => void;
    updateAssessment: (candidate: Candidate, questionnaire: Questionnaire) => void;
}

export type AnsweredDpaAssessmentQuestionnaire = { sections: AnsweredDpaAssessmentSection[] } & Questionnaire

export type AnsweredDpaAssessmentSection = { questions: AnsweredDpaAssessmentQuestion[] } & QuestionnaireSection;

export type AnsweredDpaAssessmentQuestion = { answer_value?: string } & Question; // eslint-disable-line camelcase
