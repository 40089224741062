import create from 'zustand';
import { persist } from 'zustand/middleware';
import { FeatureFlag } from '../../api/feature-flags/feature-flags.dtos';

export const useFeatureFlagsStore = create(persist<FeatureFlagsState>(
    (set) => ({
        featureFlags: [],
        setFeatureFlags: (featureFlags: FeatureFlag[]) => set({featureFlags}),
    }),
    {
        name: 'feature-flags',
        getStorage: () => sessionStorage,
    },
));

type FeatureFlagsState = {
    featureFlags: FeatureFlag[],
    setFeatureFlags: (featureFlags: FeatureFlag[]) => void;
}
