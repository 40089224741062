import React from 'react';
import { Question, QuestionRequest, useCreateQuestionMutation, useEditQuestionMutation } from '../../../../../../api/dma';
import { Label, Textarea } from '@windmill/react-ui';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useToast } from '../../../../../../hooks';
import { LoadingButton } from '../../../../../../components';

type QuestionFormValues = {
    textDe: string;
    textEn: string;
}

type Props = {
    question: Question | undefined;
    onClose: () => void;
}

export const CustomQuestionModal: React.FC<Props> = ({question = undefined, onClose: closeModal}) => {
    const {mutate: createQuestion, isLoading: isCreating} = useCreateQuestionMutation();
    const {mutate: editQuestion, isLoading: isEditing} = useEditQuestionMutation(question?.id);
    const {t} = useTranslation();
    const {successToast} = useToast();

    const isCreateQuestion = !question;
    return (
        <div className="flex space-x-6 items-end">
            <Formik
                initialValues={{
                    textDe: isCreateQuestion ? '' : question.text_de,
                    textEn: isCreateQuestion ? '' : question.text_en,
                } as QuestionFormValues}
                enableReinitialize
                onSubmit={(formFields) => {
                    const dto: QuestionRequest = {
                        text_de: formFields.textDe.trim(),
                        text_en: formFields.textEn.trim(),
                    };
                    (isCreateQuestion ? createQuestion : editQuestion)(dto, {
                        onSuccess: () => {
                            successToast(t('questions.questionModal.success' + (isCreateQuestion ? 'Create' : 'Edit')));
                            closeModal();
                        },
                    });
                }}
                validate={values => {
                    const errors: Partial<QuestionFormValues> = {};

                    if (values.textDe?.length < 3) {
                        errors.textDe = t('common.formErrors.minChars', {size: 3});
                    }
                    if (values.textEn?.length < 3) {
                        errors.textEn = t('common.formErrors.minChars', {size: 3});
                    }

                    return errors;
                }}
            >
                {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isValid,
                  }) => (
                    <form className="w-full mt-5 space-y-5" onSubmit={handleSubmit}>
                        <div className="flex flex-col">
                            <span>{t('questions.questionModal.fields.textDe')}</span>
                            <Label className="flex mt-1">
                                <Textarea
                                    css=""
                                    className="min-h-15"
                                    name="textDe"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.textDe}
                                    valid={!errors.textDe}
                                />
                            </Label>
                            {errors.textDe && <div className="text-xs text-red-700">{errors.textDe}</div>}
                        </div>
                        <div className="flex flex-col">
                            <span>{t('questions.questionModal.fields.textEn')}</span>
                            <Label className="flex mt-1">
                                <Textarea
                                    css=""
                                    className="min-h-15"
                                    name="textEn"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.textEn}
                                    valid={!errors.textEn}
                                />
                            </Label>
                            {errors.textEn && <div className="text-xs text-red-700">{errors.textEn}</div>}
                        </div>

                        <LoadingButton
                            isLoading={isCreating || isEditing}
                            disabled={!isValid || Object.keys(errors).length > 0 || isCreating || isEditing}
                        >
                            {t('questions.questionModal.submit' + (isCreateQuestion ? 'Create' : 'Edit'))}
                        </LoadingButton>
                    </form>
                )}
            </Formik>
        </div>
    );
};
