import { useQuery } from '@tanstack/react-query';
import { FeatureFlag } from './feature-flags.dtos';

const TEN_HOURS = 10 * 60 * 60 * 1000;

export const useFeatureFlagsQuery = () => {
    return useQuery(
        ['featureFlags'],
        () => [] as FeatureFlag[],
        // TODO ms
        // GetQuery<FeatureFlag[]>(`/api/featureFlags`),
        {
            staleTime: TEN_HOURS,
            cacheTime: TEN_HOURS,
        },
    );
};
