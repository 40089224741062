import { Button } from '@windmill/react-ui';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactCrop from 'react-image-crop';
import AvatarImage from '../../../assets/img/avatar.jpeg';
import { useUploadProfileImageMutation } from '../../../api/users';
import { useAuth } from '../../../hooks/use-auth/use-auth';
import { useToast } from '../../../hooks';
import { CropObject } from 'react-image-crop/dist/types';

export const ProfileImage: React.FC = () => {
    const [img, setImg] = useState(null);
    // noinspection PointlessArithmeticExpressionJS
    const [crop, setCrop] = useState<Partial<CropObject>>({unit: '%', width: 40, aspect: 1 / 1});
    const [completedCrop, setCompletedCrop] = useState(null);
    const imgRef = useRef(null);
    const inputFile = useRef<HTMLInputElement>(null);
    const previewCanvasRef = useRef<HTMLCanvasElement>(null);
    const {t} = useTranslation();
    const {successToast} = useToast();
    const {user, setUser} = useAuth();
    const {
        mutate: uploadProfileImage,
    } = useUploadProfileImageMutation({
        onSuccess: (updatedUser) => {
            setUser(updatedUser);
            if (imgRef.current) {
                setImg(null);
                setCompletedCrop(null);
                imgRef.current = null;
                successToast(t('users.profileImageUploaded'));
            }
        },
    });

    // Drawing image on canvas after cropping is done
    useEffect(() => {
        if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
            return;
        }

        const image = imgRef.current;
        const canvas = previewCanvasRef.current;
        const crop = completedCrop;

        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        const ctx = canvas.getContext('2d');
        const pixelRatio = window.devicePixelRatio;

        canvas.width = crop.width * pixelRatio;
        canvas.height = crop.height * pixelRatio;

        ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
        ctx.imageSmoothingQuality = 'high';

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height,
        );
    }, [completedCrop]);

    const onSelectFile = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files.length > 0) {
            const reader = new FileReader();
            reader.addEventListener('load', () => setImg(reader.result));
            reader.readAsDataURL(e.target.files[0]);
        }
    };

    const onInputFileClick = (e: React.MouseEvent<HTMLInputElement>) => {
        e.target = null;
    };

    const handleOpenFileExplorer = () => {
        inputFile.current.click();
    };

    const handleImageUpload = () => {
        previewCanvasRef.current.toBlob(blob => {
            const image = new File([blob], 'profile-image.jpeg', {type: 'image/jpeg'});
            const formdata = new FormData();
            formdata.append('image', image);
            uploadProfileImage(formdata);
        });
    };

    return (
        <div className="flex-col align-middle justify-center">
            <input
                ref={inputFile} className="hidden" type="file" accept="image/*"
                onChange={onSelectFile}
                onClick={onInputFileClick}
            />
            <p className="text-sm text-gray-700 dark:text-gray-400 ">{t('users.profileImage')}</p>
            <div className="relative w-40 mb-5 mt-1">

                {!img && <img
                    className="relative w-full" alt="User profile avatar"
                    src={user?.profile_image ? `${process.env.REACT_APP_FILES_URL}/${user.profile_image}` : AvatarImage}
                />}

                {/* If an image is uploaded, show crop tool */}
                {img && <ReactCrop
                    className="relative w-full"
                    src={img}
                    onImageLoaded={(img) => { imgRef.current = img; }}
                    crop={crop}
                    onChange={setCrop}
                    onComplete={setCompletedCrop}
                />}

                {/* For generating temporary cropped image on canvas */}
                <div className="hidden">
                    <canvas
                        ref={previewCanvasRef}
                        // Rounding is important so the canvas width and height matches/is a multiple for sharpness.
                        style={{
                            width: Math.round(completedCrop?.width ?? 0),
                            height: Math.round(completedCrop?.height ?? 0),
                        }}
                    />
                </div>
            </div>
            <div className="lg:text-center">
                {!img && <Button onClick={handleOpenFileExplorer}>{t('users.chooseImage')}</Button>}
                {img && <Button onClick={handleImageUpload}>{t('users.uploadImage')}</Button>}
            </div>
        </div>
    );
};
