import React, { useState } from 'react';
import { Button } from '@windmill/react-ui';
import { EditIcon, TrashIcon } from '../../../../assets/icons';
import { Modal } from '../../../elements/modal/modal';
import { useTranslation } from 'react-i18next';
import { SortableTableProps } from '../sortable-table.props';

type SortableTableRowButtonsProps = {
    row: any;
    readableRowIdentifier: SortableTableProps<any>['readableRowIdentifier'];
    onEditPress?: (row: any) => void;
    onDeletePress?: (row: any) => void;
    extraOptions?: (row) => React.ReactNode;
}

const SortableTableRowButtons: React.FC<SortableTableRowButtonsProps> = (props) => {
    const {
        row,
        readableRowIdentifier,
        onEditPress,
        onDeletePress,
        extraOptions,
    } = props;

    const {t} = useTranslation();
    const [deleteConfirmationAlert, setDeleteConfirmationAlert] = useState(false);
    const [deleteConfirmationAlertBody, setDeleteConfirmationAlertBody] = useState('');
    const [toDelete, setToDelete] = useState();

    const onEdit = (row) => {
        onEditPress(row);
    };
    const onDelete = (row) => {
        setDeleteConfirmationAlertBody(t('sortableTable.deleteConfirmation.body', {record: `'${readableRowIdentifier(row)}'`}));
        setDeleteConfirmationAlert(true);
        setToDelete(row);
    };
    const onDeleteConfirm = () => {
        setDeleteConfirmationAlert(false);
        onDeletePress(toDelete);
    };
    const onDeleteCancel = () => {
        setDeleteConfirmationAlert(false);
    };

    return (
        <>
            <div className="flex items-center justify-end">
                {extraOptions?.(row)}
                {onEditPress &&
                    <Button layout="link" size="small" onClick={() => onEdit(row)}>
                        <EditIcon className="w-5 h-5" aria-hidden="true" />
                    </Button>
                }
                {onDeletePress &&
                    <Button layout="link" size="small" onClick={() => onDelete(row)}>
                        <TrashIcon className="w-5 h-5" aria-hidden="true" />
                    </Button>
                }
            </div>
            <Modal
                isOpen={deleteConfirmationAlert}
                headerTx="sortableTable.deleteConfirmation.header"
                acceptLabelTx="sortableTable.deleteConfirmation.acceptButton"
                onAccept={onDeleteConfirm}
                onClose={onDeleteCancel}
            >
                {deleteConfirmationAlertBody}
            </Modal>
        </>
    );
};

export default SortableTableRowButtons;
