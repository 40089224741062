import Lottie from 'lottie-react';
import React from 'react';
import { AnimatedIconProps } from './animated-icon.props';
import { icons } from './icons';

export const AnimatedIcon: React.FC<AnimatedIconProps> = (props) => {
    const {icon, size = 40, style, ...rest} = props;

    const populateStyle = {
        ...style,
        height: `${size}px`,
        width: `${size}px`,
    };

    return <Lottie animationData={icons[icon]} loop={true} style={populateStyle} {...rest} />;
};
