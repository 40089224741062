import React, { lazy } from 'react';
import { PermissionMatcher } from './hooks/use-auth/use-auth';

const Login = lazy(() => import('./apps/login/app'));
const Admin = lazy(() => import('./apps/admin/app'));
const Assessment = lazy(() => import('./apps/assessment/app'));
const ResetPassword = lazy(() => import('./components/reset-password/reset-password'));
const AssessmentLinkPresentationView = lazy(() => import('./apps/admin/components/dma/assessments/link-presentation-view/link-presentation-view'));

export type PageRoute = {
    path: string;
    component: React.ExoticComponent<any>;
    permissionMatcher?: PermissionMatcher;
}

export type TopLevelPageRoute = PageRoute & {
    hasSubRoutes: boolean;
}

export const ROUTES: TopLevelPageRoute[] = [
    {
        path: '/login',
        component: Login,
        hasSubRoutes: false,
    },
    {
        // TODO ms: should this be restricted by permissions?
        path: '/admin/dma/assessments/:link/present',
        component: AssessmentLinkPresentationView,
        hasSubRoutes: false,
    },
    {
        path: '/admin',
        component: Admin,
        hasSubRoutes: true,
        permissionMatcher: 'AUTHENTICATED',
    },
    { // DEPRECATED
        path: '/assessment',
        component: Assessment,
        hasSubRoutes: true,
    },
    {
        path: '/dpa',
        component: Assessment,
        hasSubRoutes: true,
    },
    {
        path: '/dma',
        component: Assessment,
        hasSubRoutes: true,
    },
    {
        path: '/reset-password/:token',
        component: ResetPassword,
        hasSubRoutes: false,
    },
];
