import { Button, Input } from '@windmill/react-ui';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSlate } from 'slate-react';
import { FormatLinkIcon } from '../../../../../assets/icons';
import { Modal } from '../../../../elements/modal/modal';
import { Span } from '../span/span';
import { LinkButtonProps } from './link-button.props';

export const LinkButton: React.FC<LinkButtonProps> = (props) => {
    const editor = useSlate();
    const {t} = useTranslation();

    const {
        format, isActive, toggle, currentSelection, showPopup, popupCallback = () => {
        },
    } = props;

    const [urlValue, setUrlValue] = useState('');

    useEffect(() => {
        setUrlValue(currentSelection ? currentSelection.url : '');
    }, [currentSelection, setUrlValue]);

    const handleSubmit = () => {
        popupCallback(editor, format, urlValue);
    };

    return (
        <div className="relative">
            <Span
                active={isActive(editor, format)}
                icon={FormatLinkIcon}
                onMouseDown={event => {
                    event.preventDefault();
                    toggle(editor, format);
                }}
            />

            <Modal
                isOpen={showPopup}
                hasFooter={false}
                headerTx={t('assessment.result.commentModal.urlModalTitle')}
                onClose={handleSubmit}
            >
                <div className="flex space-x-1">
                    <Input
                        css=""
                        name="url"
                        onChange={e => setUrlValue(e.target.value)}
                        value={urlValue}
                        placeholder={t('assessment.result.commentModal.urlPlaceholder')}
                    />
                    <Button className="h-auto" size="regular" onClick={handleSubmit}>{t('common.submit')}</Button>
                </div>
            </Modal>
        </div>
    );
};
