import { Avatar, TableCell } from '@windmill/react-ui';
import React from 'react';
import { useTranslation } from 'react-i18next';
import AvatarImage from '../../../assets/img/avatar.jpeg';
import { prettyPrintDate, prettyPrintTime } from '../../../utils/date-format';
import { CellDataType } from './cell-data.types';

export type TableValueCellProps = CellDataType & {
    value: any;
}

export const TableValueCell: React.FC<TableValueCellProps> = (props) => {
    const {
        value,
        dataType = 'string',
        withAvatar,
        avatar = '',
        highlight,
    } = props;

    const {i18n} = useTranslation();

    const switchByDataType = () => {
        switch (dataType) {
            case 'date':
                return value ? prettyPrintDate(new Date(value), i18n.language) : '';
            case 'datetime':
                return value ? prettyPrintTime(new Date(value), i18n.language) : '';
            case 'string':
            case 'number':
            default:
                return value;
        }
    };

    const renderBasic = () => {
        return highlight
            ? <p className="font-semibold">{switchByDataType()}</p>
            : <span className="text-sm">{switchByDataType()}</span>;
    };

    const renderWithAvatar = () => {
        return (
            <div className="flex items-center text-sm">
                <Avatar
                    className="hidden mr-3 md:block"
                    src={avatar ? `${process.env.REACT_APP_FILES_URL}/${avatar}` : AvatarImage}
                    alt="Avatar"
                />
                <div>{renderBasic()}</div>
            </div>
        );
    };

    return (
        <TableCell>
            {withAvatar
                ? renderWithAvatar()
                : renderBasic()
            }
        </TableCell>
    );
};
