/* eslint-disable camelcase */
import { AdminAppType } from '../../apps/admin/app';

export enum PermissionAction {
    READ = 1, // permission to view resource
    WRITE = 2, // permission to create/update/delete resource
}

export enum PermissionScope {
    COMPANY = 1, // permission to perform action for the company authenticated user belongs to
    GLOBAL = 2, // permission to perform action for all companies
}

export type PermissionResource = 'USERS' | 'COMPANIES' | 'ASSESSMENTS' | 'QUESTIONS'; // TODO ms: does CANDIDATES need to be separate from ASSESSMENTS?

export type Permission = {
    app: Uppercase<AdminAppType>;
    resource: PermissionResource;
    action: PermissionAction;
    scope: PermissionScope;
}

export type Role = {
    id: number;
    name: string;
    level: string;
    display_name: string;
    permissions: Permission[];
}

export type User = {
    id: number;
    name: string;
    email: string;
    profile_image: string;
    role: Role;
    role_id: number;
    company: string;
    company_id: number;
    created_at: string;
}

export type LoginUserRequest = Pick<User, 'email'> & { password: string, remember: boolean }

export type ForgotPasswordRequest = {
    email: string;
}

export type ResetPasswordRequest = {
    new_password: string;
    confirm_password: string;
    token: string;
}

export type CreateUserRequest = Pick<User, 'name' | 'email' | 'company_id' | 'role_id'> & {
    password: string;
}

export type UpdateUserRequest = Partial<Omit<CreateUserRequest, 'password'>>
