import isHotkey from 'is-hotkey';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createEditor, Editor, Transforms } from 'slate';
import { withHistory } from 'slate-history';
import { Editable, Slate, withReact } from 'slate-react';
import {
    FormatBoldIcon,
    FormatItalicIcon,
    FormatLinkOffIcon,
    FormatListBulletedIcon,
    FormatListNumberedIcon,
    FormatQuoteIcon,
    FormatSubtitleIcon,
    FormatTitleIcon,
    FormatUnderlinedIcon,
} from '../../../assets/icons';
import { BlockButton, Element, Leaf, LinkButton, MarkButton } from './components';
import { RichTextInputProps } from './rich-text-input.props';

const HOTKEYS = {
    'mod+b': 'bold',
    'mod+i': 'italic',
    'mod+u': 'underline',
    'mod+k': 'link',
};

export const RichTextInput: React.FC<RichTextInputProps> = (props) => {
    const {
        className = '',
        value,
        onChange = () => { },
        placeholderTx = 'richTextEditor.placeholder',
    } = props;

    const {t} = useTranslation();

    const [openPopup, setOpenPopup] = useState(false);
    const [currentSelection, setCurrentSelection] = useState(null);

    const renderElement = useCallback(props => <Element {...props} />, []);
    const renderLeaf = useCallback(props => <Leaf {...props} />, []);
    const editor = useMemo(() => withHistory(withReact(createEditor())), []);

    const isMarkActive = (editor, format) => {
        const marks = Editor.marks(editor);
        return marks ? !!marks[format] === true : false;
    };

    const openUrlModal = (editor) => {
        const marks = Editor.marks(editor);
        setCurrentSelection({
            selection: editor.selection,
            url: marks.link || '',
        });
        setOpenPopup(true);
    };

    const hasUrl = (editor, format) => {
        const marks = Editor.marks(editor);
        return marks && marks[format] === 'link';
    };

    const removeUrl = (editor) => {
        Editor.removeMark(editor, 'link');
    };

    const toggleMark = (editor, format) => {
        const isActive = isMarkActive(editor, format);

        if (isActive && format !== 'link') {
            Editor.removeMark(editor, format);
        } else {
            Editor.addMark(editor, format, true);
        }
    };

    const setLinkValue = (editor, format, value: string) => {
        Transforms.select(editor, currentSelection.selection);
        Editor.addMark(editor, format, value);

        setOpenPopup(false);
    };

    return (
        <div className={className}>
            <Slate editor={editor} value={value} onChange={onChange}>
                <div className={'flex flex-cols space-x-2 pt-1 pb-2 -mx-1 mb-5 border-b-2 border-gray-200'}>
                    <MarkButton format="bold" icon={FormatBoldIcon} isActive={isMarkActive} toggle={toggleMark} />
                    <MarkButton format="italic" icon={FormatItalicIcon} isActive={isMarkActive} toggle={toggleMark} />
                    <MarkButton format="underline" icon={FormatUnderlinedIcon} isActive={isMarkActive} toggle={toggleMark} />
                    <BlockButton format="title" icon={FormatTitleIcon} />
                    <BlockButton format="subtitle" icon={FormatSubtitleIcon} />
                    <BlockButton format="block-quote" icon={FormatQuoteIcon} />
                    <BlockButton format="numbered-list" icon={FormatListNumberedIcon} />
                    <BlockButton format="bulleted-list" icon={FormatListBulletedIcon} />
                    <LinkButton
                        format="link"
                        isActive={hasUrl && isMarkActive}
                        toggle={openUrlModal}
                        currentSelection={currentSelection}
                        showPopup={openPopup}
                        popupCallback={setLinkValue}
                    />
                    {hasUrl &&
                        <MarkButton
                            format="link"
                            icon={FormatLinkOffIcon}
                            isActive={hasUrl && isMarkActive}
                            toggle={removeUrl}
                        />
                    }
                </div>
                <div className="h-64 overflow-y-auto overflow-x-hidden pb-5">
                    <Editable
                        renderElement={renderElement}
                        renderLeaf={renderLeaf}
                        placeholder={t(placeholderTx)}
                        autoFocus
                        onKeyDown={event => {
                            for (const hotkey in HOTKEYS) {
                                if (isHotkey(hotkey, event as any)) {
                                    event.preventDefault();
                                    const mark = HOTKEYS[hotkey];

                                    toggleMark(editor, mark);
                                }
                            }
                        }}
                    />
                </div>
            </Slate>
        </div>
    );
};
