import create from 'zustand';
import { persist } from 'zustand/middleware';
import { User } from '../../api/users';

export const useAuthStore = create(persist<AuthState>(
    (set) => ({
        user: null,
        setUser: (user: User) => set({user: user}),
    } as AuthState),
    {
        name: 'auth',
    }),
);

type AuthState = {
    user: User,
    setUser: (user: User) => void;
}
