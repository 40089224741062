import React from 'react';
import { Select } from '@windmill/react-ui';
import { useTranslation } from 'react-i18next';

const ROWS_OPTIONS = [
    10, 25, 50, 100,
];

type SortableTableRowsPickerProps = {
    rowsPerPage: number;
    setRowsPerPage: (rowsPerPage: number) => void;
}

const SortableTableRowsPicker: React.FC<SortableTableRowsPickerProps> = ({rowsPerPage, setRowsPerPage}) => {
    const {t} = useTranslation();

    return (
        <div className={'flex px-4 pt-3 pb-1 text-xs sm:flex-row text-gray-600'}>
            <span className={'font-semibold mt-2 mr-4 tracking-wide uppercase whitespace-nowrap'}>
                {t('sortableTable.rowsPerPage')}
            </span>
            <div className="w-20">
                <Select
                    css=""
                    className="py-1"
                    name="rowsPerPage"
                    onChange={e => setRowsPerPage(parseInt(e.target.value))}
                    value={rowsPerPage}
                >
                    {ROWS_OPTIONS.map((option, i) => (
                        <option key={i} value={option}>{option}</option>
                    ))}
                </Select>
            </div>
        </div>
    );
};

export default SortableTableRowsPicker;
