import { useMemo, useState } from 'react';

export type SortableTableSortConfig = {
    key?: string;
    direction?: 'ascending' | 'descending' | '';
}

export const useSortableData = (items: any, config: SortableTableSortConfig) => {
    const [sortConfig, setSortConfig] = useState(config);

    const sortedItems = useMemo(() => {
        const sortableItems = [...items];

        if (sortConfig !== null) {
            sortableItems.sort((a, b) => {
                const itemA = typeof (a[sortConfig.key]) === 'string' ? a[sortConfig.key].toLowerCase() : a[sortConfig.key];
                const itemB = typeof (b[sortConfig.key]) === 'string' ? b[sortConfig.key].toLowerCase() : b[sortConfig.key];

                if (
                    itemA < itemB
                    || ((itemA === null || itemA === undefined) && itemB)
                ) {
                    return sortConfig.direction === 'ascending' ? -1 : 1;
                }

                if (
                    itemA > itemB
                    || ((itemB === null || itemB === undefined) && itemA)
                ) {
                    return sortConfig.direction === 'ascending' ? 1 : -1;
                }

                return 0;
            });
        }

        return sortableItems;
    }, [items, sortConfig]);

    const requestSort = (key: string, direction = null) => {
        if (direction) {
            setSortConfig({key, direction});
            return;
        }

        direction = 'ascending';

        if (
            sortConfig &&
            sortConfig.key === key &&
            sortConfig.direction === 'ascending'
        ) {
            direction = 'descending';
        }

        setSortConfig({key, direction});
    };

    return {items: sortedItems, requestSort, sortConfig};
};
