import { ApiResponse } from 'apisauce';
import values from 'lodash/values';
import { ApiErrorResponse } from './index';

export type ApiErrorKind =
    | 'timeout'
    | 'cannot-connect'
    | 'server'
    | 'unauthorized'
    | 'forbidden'
    | 'not-found'
    | 'too-many-requests'
    | 'rejected'
    | 'unknown'
    | 'bad-data'
    | 'unprocessable-entity';

export function convertToApiError(response: ApiResponse<any>): ApiErrorResponse {
    let message = response.data?.message;

    if (response.data?.errors) {
        if (typeof response.data.errors === 'object') {
            const firstKey = values(response.data.errors)[0] || false;
            message = firstKey ? firstKey[0] : message;
        } else {
            message = response.data.errors;
        }
    }

    switch (response.problem) {
        /**
         * Cannot connect to the server for some reason.
         */
        case 'CONNECTION_ERROR':
            return {kind: 'cannot-connect'};
        case 'NETWORK_ERROR':
            return {kind: 'cannot-connect'};
        /**
         * Times up.
         */
        case 'TIMEOUT_ERROR':
            return {kind: 'timeout'};
        /**
         * The server experienced a problem. Any 5xx error.
         */
        case 'SERVER_ERROR':
            return {kind: 'server'};
        /**
         * Something truly unexpected happened. Most likely can try again. This is a catch all.
         */
        case 'UNKNOWN_ERROR':
            return {kind: 'unknown'};
        case 'CLIENT_ERROR':
            switch (response.status) {
                /**
                 * We're not allowed because we haven't identified ourselves.
                 */
                case 401:
                case 419:
                    return {kind: 'unauthorized'};
                /**
                 * We don't have access to perform that request.
                 */
                case 403:
                    return {kind: 'forbidden'};
                /**
                 * Unable to find that resource.
                 */
                case 404:
                    return {kind: 'not-found'};
                /**
                 * Unprocessable entity (missing fields or validation errors)
                 */
                case 422:
                    return {kind: 'unprocessable-entity', message};
                /**
                 * Too many requests to handle.
                 */
                case 429:
                    return {kind: 'too-many-requests', message};

                /**
                 * Link has expired
                 */
                case 410:
                    return {kind: 'bad-data', message};

                /**
                 * All other 4xx series errors.
                 */
                default:
                    return {kind: 'rejected', message};
            }
        case 'CANCEL_ERROR':
            return null;
    }

    return null;
}
