import { useMutation, UseMutationOptions, useQueryClient } from '@tanstack/react-query';
import { DeleteMutation, PostMutation } from '../../mutations';
import { AssessmentRequest, SubmitAssessmentRequest } from './assessment.dtos';
import { apisauce } from '../../api';
import { ApiResponse } from 'apisauce';
import { Locale } from '../../../stores';

export const useCreateAssessmentMutation = () => {
    const queryClient = useQueryClient();
    return useMutation(
        PostMutation<string, AssessmentRequest>(`/api/dma/assessments`),
        {
            onSuccess: () => queryClient.invalidateQueries({
                predicate: ({queryKey}) => queryKey.includes('dma') && queryKey.includes('assessments') && !queryKey.includes('byLink'),
            }),
        },
    );
};

export const useShareAssessmentMutation = (assessmentLink: string) => {
    return useMutation(
        PostMutation<void, { email: string }>(`/api/dma/assessments/byLink/${assessmentLink}/share`),
    );
};

export const useDeleteAssessmentMutation = () => {
    const queryClient = useQueryClient();
    return useMutation<void, unknown, number>(
        DeleteMutation(`/api/dma/assessments/`),
        {
            onSuccess: () => queryClient.invalidateQueries(['dma', 'assessments']),
        },
    );
};

export const useSubmitAssessmentMutation = (assessmentId: number, assessmentLink: string) => {
    const queryClient = useQueryClient();
    return useMutation(
        PostMutation<void, SubmitAssessmentRequest>(`/api/dma/assessments/${assessmentId}/submissions`),
        {
            onSuccess: () => queryClient.invalidateQueries(['dma', 'assessments', assessmentLink, 'results']),
        },
    );
};

export const useGenerateAssessmentResultPdfMutation = (assessmentLink: string, language: Locale, options: Omit<UseMutationOptions<ArrayBuffer, unknown, FormData>, 'retry'> = {}) => {
    return useMutation<ArrayBuffer, unknown, FormData>(
        async (formData: FormData) => {
            const response: ApiResponse<ArrayBuffer> = await apisauce.post(
                `/api/dma/assessments/${assessmentLink}/results/pdf/${language}`,
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'Accept': 'application/pdf', // eslint-disable-line quote-props
                    },
                    responseType: 'arraybuffer',
                    timeout: 60 * 1000,
                },
            );
            return response.data;
        },
        {
            ...options,
            retry: 1,
        },
    );
};
