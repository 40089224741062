import { Button, Dropdown } from '@windmill/react-ui';
import React from 'react';
import { ChevronDownSmallIcon } from '../../../assets/icons';
import { Props as ButtonProps } from '@windmill/react-ui/dist/Button';
import { useClickOutside, useDisclosure } from '@mantine/hooks';

type Props = {
    buttonText: string;
    buttonLayout?: ButtonProps['layout'];
    align?: 'right' | 'left';
    stretch?: boolean;
    closeOnClick?: boolean;
    children: React.ReactNode;
}

export const DropDown: React.FC<Props> = (props) => (
    <div className="relative">
        <RelativeDropDown {...props}>
            {props.children}
        </RelativeDropDown>
    </div>
);

/**
 * Separate component is needed for "useClickOutside".
 */
const RelativeDropDown: React.FC<Props> = (props) => {
    const {
        buttonText,
        align = 'right',
        buttonLayout = 'outline',
        stretch = false,
        closeOnClick = false,
        children,
    } = props;

    const [isOpen, {toggle, close}] = useDisclosure(false);
    const ref = useClickOutside(close);

    return (
        <div className={'justify-between' + (stretch ? ' flex flex-grow' : '')} ref={ref}>
            <Button
                className={'flex row focus:shadow-outline-purple focus:outline-none whitespace-no-wrap' + (stretch ? ' flex-grow' : '')}
                onClick={toggle}
                layout={buttonLayout}
                aria-haspopup="true"
            >
                <span className="inline-block">{buttonText}</span>
                <ChevronDownSmallIcon className="w-5 h-5 descending ml-auto" aria-hidden="true" />
            </Button>
            <Dropdown
                isOpen={isOpen} onClose={() => { }} align={align} onClick={() => { if (closeOnClick) close(); }}
                className={'z-50' + (stretch ? ' mt-10' : '')}
            >
                {children}
            </Dropdown>
        </div>
    );
};
