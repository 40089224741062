import { Button } from '@windmill/react-ui';
import { ButtonAsButtonProps } from '@windmill/react-ui/dist/Button';
import classNames from 'classnames';
import React from 'react';

export const DangerButton: React.FC<ButtonAsButtonProps> = (props) => {
    const className = classNames('bg-red-500', 'hover:bg-red-600', 'focus:shadow-outline-red', props.className || '');

    return (
        <Button {...props} layout="link" className={className}>
            <span className="text-white">{props.children}</span>
        </Button>
    );
};
