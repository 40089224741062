import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { PostMutation } from '../../mutations';
import {
    CreateCandidateAndAssignAssessmentRequest,
    GenerateAssessmentLinkRequest,
    GenerateBulkAssessmentLinkRequest,
    GenerateCandidateAssessmentLinkRequest,
    GeneratedAssessmentLink,
    NewAssessmentCandidateAndQuestionnaire,
    SubmitAssessmentRequest,
} from './assessment.dtos';

export const useGenerateAssessmentLinkMutation = (options: UseMutationOptions<GeneratedAssessmentLink, unknown, GenerateAssessmentLinkRequest> = {}) => {
    return useMutation(
        PostMutation<GeneratedAssessmentLink, GenerateAssessmentLinkRequest>(`/api/dpa/assessments/generate-link`),
        options,
    );
};

export const useGenerateBulkAssessmentLinkMutation = (options: UseMutationOptions<GeneratedAssessmentLink, unknown, GenerateBulkAssessmentLinkRequest> = {}) => {
    return useMutation(
        PostMutation<GeneratedAssessmentLink, GenerateBulkAssessmentLinkRequest>(`/api/dpa/assessments/generate-link/bulk`),
        options,
    );
};

export const useGenerateCandidateAssessmentLinkMutation = (options: UseMutationOptions<GeneratedAssessmentLink, unknown, GenerateCandidateAssessmentLinkRequest> = {}) => {
    return useMutation(
        PostMutation<GeneratedAssessmentLink, GenerateCandidateAssessmentLinkRequest>(`/api/dpa/assessments/generate-link/candidate`),
        options,
    );
};

export const useSubmitAssessmentMutation = () => {
    return useMutation(
        PostMutation<void, SubmitAssessmentRequest>(`/api/dpa/results`),
    );
};

export const useCreateCandidateAndAssignAssessmentMutation = () => {
    return useMutation(
        PostMutation<NewAssessmentCandidateAndQuestionnaire, CreateCandidateAndAssignAssessmentRequest>(`/api/dpa/assessments/new-candidate`),
    );
};
