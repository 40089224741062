import { usePathname } from '../use-pathname/use-pathname';

/**
 * Returns the currently active admin app or undefined
 * @return {AssessmentAppType} 'dma' or 'dpa'
 */
export const useAssessmentApp = (): AssessmentAppType => {
    const pathname = usePathname();
    return /^\/(d[mp]a).*/.exec(pathname)?.pop() as AssessmentAppType;
};

export type AssessmentAppType = 'dpa' | 'dma';
