import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { GetQuery } from '../../queries';
import { Assessment, AssessmentByLink } from './assessment.dtos';

export const useGetAssessmentsQuery = () => {
    return useQuery(
        ['dpa', 'assessments'],
        GetQuery<Assessment[]>(`/api/dpa/assessments`),
    );
};

export const useGetAssessmentByLinkQuery = (link: string, options: UseQueryOptions<AssessmentByLink> = {}) => {
    return useQuery(
        ['dpa', 'assessments', 'by-link', link],
        GetQuery<AssessmentByLink>(`/api/dpa/assessments/by-link/${link}`),
        options,
    );
};

export const useGetAssessmentLinkByParamsQuery = (params: string, options: UseQueryOptions<string> = {}) => {
    return useQuery(
        ['dpa', 'assessments', 'params-to-link', params],
        GetQuery<string>(`/api/dpa/assessments/params-to-link${params}`),
        options,
    );
};
