import React, { Suspense } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import Image from '../../assets/img/error.svg';
import { AppLoader } from '../loaders/app-loader/app-loader';

const ErrorFallback: React.FC = () => {
    return (
        <div className="flex flex-col items-center">
            <img src={Image} alt="error" className="mt-8 w-3/4 lg:w-1/4" />
            <h1 className="text-3xl font-semibold text-gray-700 dark:text-gray-200 mt-4 mb-2">Error</h1>
            <p className="text-gray-700 dark:text-gray-300">
                An unknown error occurred. Please reload the page.
            </p>
        </div>
    );
};

export const AppFallback: React.FC = ({children}) => {
    return (
        <>
            <ErrorBoundary fallback={<ErrorFallback />}>
                <Suspense fallback={<AppLoader />}>
                    {children}
                </Suspense>
            </ErrorBoundary>
        </>
    );
};
