import React from 'react';
import { CopyrightProps } from './copyright.props';

export const Copyright: React.FC<CopyrightProps> = ({className, ...rest}) => {
    return (
        <span className={`text-xs font-medium text-gray-600 dark:text-gray-400 ${className}`} {...rest}>
      &copy; {new Date().getFullYear()} Search & Co. Group
    </span>
    );
};
