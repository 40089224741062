import { Button, Table, TableBody, TableCell, TableContainer, TableFooter, TableRow } from '@windmill/react-ui';
import debounce from 'lodash/debounce';
import React, { ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSortableData } from '../../../hooks';
import { AnimatedIcon } from '../../elements/animated-icon/animated-icon';
import { TableValueCell } from '../cells/table-value-cell';
import { SortableTableProps } from './sortable-table.props';
import { useLocalTableConfigStore } from '../../../hooks/use-local-table-config-store/use-local-table-config-store';
import SortableTableRowButtons from './row-buttons/sortable-table-row-buttons';
import SortableTableRowsPicker from './rows-picker/sortable-table-rows-picker';
import SortableTableHeader from './header/sortable-table-header';
import { TableActionCell } from '../cells/table-action-cell';
import { Pagination } from '../pagination/pagination';

export const SortableTable = <T, >(props: SortableTableProps<T>) => {
    const {useTableConfigStore = useLocalTableConfigStore, useTableSearchStore} = props;
    const {
        page, setPage,
        rowsPerPage, setRowsPerPage,
        sortConfig: storedSortConfig, setSortConfig,
    } = useTableConfigStore();
    const searchTerm = useTableSearchStore(state => state.term);
    const setSearchTerm = useTableSearchStore(state => state.setTerm);
    const setSearchFiltered = useTableSearchStore(state => state.setFiltered);

    const {
        data,
        initialCount,
        readableRowIdentifier,
        tableHeader,
        txPrefix = '',
        sortConfig: parentSortConfig = storedSortConfig,
        isLoading = false,
        onEditPress,
        onDeletePress,
        onToggleSelect,
        extraOptions,
        headerOption,
    } = props;

    const {t} = useTranslation();
    const [pageData, setPageData] = useState([]);
    const [sortableData, setSortableData] = useState(data);
    const [total, setTotal] = useState(initialCount || data.length);
    const showOptionsRow = !!extraOptions || !!onEditPress || !!onDeletePress;

    const {items, requestSort, sortConfig} = useSortableData(sortableData, parentSortConfig);
    useEffect(() => setSortConfig(sortConfig), [setSortConfig, sortConfig]);

    const slicePageData = useCallback((toSlice) => {
        setPageData(toSlice.slice((page - 1) * rowsPerPage, page * rowsPerPage));
    }, [page, rowsPerPage]);

    const initializePage = useCallback((data) => {
        setTotal(initialCount || data.length);
        setSortableData(data);
        slicePageData(data);
    }, [initialCount, slicePageData]);

    const searchData = useCallback((term: string, data) => {
        if (data.length) {
            if (term.length) {
                setPage(1);

                const filtered = data.filter((value) => {
                    let shouldBeIncluded = false;

                    tableHeader.every((header) => {
                        if (value[header.field] && value[header.field].toString().toLowerCase().includes(term.toLowerCase())) {
                            shouldBeIncluded = true;
                            return false;
                        } else {
                            return true;
                        }
                    });
                    return shouldBeIncluded;
                });

                setSearchTerm(term);
                setSearchFiltered(filtered);

                initializePage(filtered);
            } else {
                setSearchTerm('');
                setSearchFiltered([]);

                setTotal(data.length);
                setSortableData(data);
            }
        }
    }, [initializePage, setPage, setSearchFiltered, setSearchTerm, tableHeader]);

    const searchDataDebounced = useRef(debounce(searchData, 300)).current;

    // Search data on search input change
    useEffect(() => {
        searchDataDebounced(searchTerm, data);
    }, [data, searchTerm, searchDataDebounced]);

    // Initialize page data with data received via props
    useEffect(() => {
        initializePage(items);
        if (searchTerm) {
            searchData(searchTerm, data);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, searchData, searchTerm, initializePage]);

    // Page change detected, display different slice of data
    useEffect(() => {
        slicePageData(items);
    }, [page, items, slicePageData]);

    const renderLoading = () => {
        return (
            <TableRow>
                <TableCell className="w-full" colSpan={tableHeader.length + 1}>
                    <AnimatedIcon icon="loadingColor" className={'my-40 mx-auto'} size={200} />
                </TableCell>
            </TableRow>
        );
    };

    const renderTableCellItem = (row, i, {field, withAvatar, avatar, ...rest}, cellIndex) => {
        if (onToggleSelect && cellIndex === 0) {
            return (
                <TableActionCell
                    key={`${i}-${cellIndex}`} cellKey={`${i}-${cellIndex}`}
                    row={row}
                    onChange={onToggleSelect}
                    {...rest}
                />
            );
        } else {
            return (
                <TableValueCell
                    key={`${i}-${cellIndex}`}
                    value={row[field]}
                    withAvatar={withAvatar}
                    avatar={withAvatar && avatar ? row[avatar] : ''}
                    {...rest}
                />
            );
        }
    };

    const renderBody = () => {
        return pageData.map((row, i) => {
            return (
                <TableRow key={i}>
                    {tableHeader.map(({field, withAvatar, avatar, ...rest}, cellIndex) => (
                        renderTableCellItem(row, i, {field, withAvatar, avatar, ...rest}, cellIndex)
                    ))}
                    {showOptionsRow && (
                        <TableCell>
                            <SortableTableRowButtons
                                row={row}
                                readableRowIdentifier={readableRowIdentifier}
                                onEditPress={onEditPress}
                                onDeletePress={onDeletePress}
                                extraOptions={extraOptions}
                            />
                        </TableCell>
                    )}
                </TableRow>
            );
        });
    };

    const scrollBarWrapper = useRef<HTMLDivElement>(null);
    const contentWrapper = useRef<HTMLDivElement>(null);

    const handleScroll: React.EventHandler<React.UIEvent<ReactNode>> = (event: React.UIEvent<React.ReactNode>) => {
        const targetDiv: HTMLDivElement = event.target as HTMLDivElement;

        if (targetDiv === scrollBarWrapper.current && contentWrapper.current) {
            contentWrapper.current.scrollLeft = targetDiv.scrollLeft;
        } else if (targetDiv === contentWrapper.current && scrollBarWrapper.current) {
            scrollBarWrapper.current.scrollLeft = targetDiv.scrollLeft;
        }
    };

    return (
        <>
            {total > 0 && <TableContainer className="table-sortable" onScroll={handleScroll}>
                <div className="flex justify-between">
                    <SortableTableRowsPicker
                        rowsPerPage={rowsPerPage}
                        setRowsPerPage={setRowsPerPage}
                    />
                    {headerOption && (
                        <Button
                            layout="link" className="whitespace-no-wrap mt-2 mr-2"
                            iconRight={() => <span className="ml-1">{headerOption.icon}</span>}
                            onClick={headerOption.action}
                        >
                            {headerOption.title}
                        </Button>
                    )}
                </div>
                <div
                    ref={scrollBarWrapper} className="h-5 w-full overflow-x-scroll overflow-y-hidden"
                    onScroll={handleScroll}
                >
                </div>
                <div
                    ref={contentWrapper} className="w-full overflow-x-scroll overflow-y-hidden"
                    onScroll={handleScroll}
                >
                    <div className="min-w-full overflow-auto">
                        <Table>
                            <SortableTableHeader
                                headerRows={tableHeader}
                                sortConfig={sortConfig}
                                onRequestSort={requestSort}
                                txPrefix={txPrefix}
                                showOptionsRow={showOptionsRow}
                            />
                            <TableBody>
                                {isLoading
                                    ? renderLoading()
                                    : renderBody()
                                }
                            </TableBody>
                        </Table>
                    </div>
                </div>
                <TableFooter>
                    <Pagination
                        activePage={page}
                        totalResults={total}
                        resultsPerPage={rowsPerPage}
                        label="Table navigation"
                        onChange={setPage}
                    />
                </TableFooter>
            </TableContainer>}
            {total === 0 && !isLoading && <h2>{t('search.noResults')}</h2>}
        </>
    );
};
