import { persist } from 'zustand/middleware';
import create from 'zustand';
import { Assessment, Question, QuestionnaireSection } from '../../api/dma';
import { DEFAULT_SECTIONS_COUNT } from '../../apps/admin/components/dma/questions/questions-list';

export const useDmaAssessmentStore = create(persist<DmaAssessmentState>(
    (set, get) => ({
        setAnswer: (sectionIndex: number, questionId: number, answerValue: number) => {
            if (answerValue < 1 || answerValue > 4) return;
            // Create a new sections array with the updated answer value to avoid direct mutation
            const updatedSections = get().sections.map((section, index) => {
                if (index === sectionIndex) {
                    return {
                        ...section,
                        questions: section.questions.map(question =>
                            question.id === questionId ? { ...question, answer_value: answerValue } : question
                        ),
                    };
                }
                return section;
            });
            // Use the set function to update the state with the new sections array
            set({ sections: updatedSections });
        },
        setParticipantInfo: (info: AssessmentParticipantInfo) => {
            // Merge new participant info with the existing one safely
            const updatedParticipantInfo = { ...get().participantInfo, ...info };
            set({ participantInfo: updatedParticipantInfo });
        },
        initialize: (questionnaireSections: QuestionnaireSection[], assessment: Assessment) => {
            // Ensure the initialization data is validated and in the correct format before setting it
            const validatedSections = (questionnaireSections || []).filter(qs => qs.order <= DEFAULT_SECTIONS_COUNT || assessment.include_custom_section);
            set({
                sections: validatedSections.map(qs => ({ ...qs })),
                assessment: { ...assessment },
                participantInfo: {}, // Make sure this is structured correctly if needed elsewhere
            });
        },
    } as DmaAssessmentState),
    {
        name: 'dma-assessment',
        getStorage: () => sessionStorage,
    },
));

type DmaAssessmentState = {
    sections: AnsweredAssessmentSection[];
    assessment: Assessment;
    participantInfo: AssessmentParticipantInfo;
    setAnswer: (sectionIndex: number, questionId: number, answerValue: number) => void;
    setParticipantInfo: (info: AssessmentParticipantInfo) => void;
    initialize: (questionnaireSections: QuestionnaireSection[], assessment: Assessment) => void;
}

export type AnsweredAssessmentSection = { questions: AnsweredAssessmentQuestion[] } & QuestionnaireSection;

export type AnsweredAssessmentQuestion = { answer_value?: number } & Question; // eslint-disable-line camelcase

export type AssessmentParticipantInfo = {
    name?: string;
    email?: string;
    company?: string;
    position?: string;
    department?: string;
    duration?: string;
    satisfaction?: string;
    industry?: string;
    size?: string;
    // eslint-disable-next-line camelcase
    division_id?: string;
}
