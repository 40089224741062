import { QueryClientProvider } from '@tanstack/react-query';
import { Windmill } from '@windmill/react-ui';
import React from 'react';
import ReactDOM from 'react-dom';
import QueryClient from './api';
import App from './app';
import './assets/css/app.output.css';
import { AppFallback } from './components';
import './i18n';
import * as serviceWorkerRegistration from './service-worker-registration';
import Theme from './theme/theme';

ReactDOM.render(
    <QueryClientProvider client={QueryClient}>
        <AppFallback>
            <Windmill theme={Theme} dark={false}>
                <App />
            </Windmill>
        </AppFallback>
    </QueryClientProvider>,
    document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();
